import { disableCloudpayments, privateKeySet } from 'actions';
import { Modal, Popconfirm } from 'antd';
import {
 BigRedButton, Container, Input, InputDescription, Title,
} from 'components/Modals/DisableCloud/styled';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { sendNotification } from 'utils/notification';

const keyForDisable = 'Ладненбко:(';

type Props = {
  hideFn: () => void,
  visible: boolean,
};

const RsaPrivate = (props: Props) => {
  const { hideFn, visible } = props;
  const [key, setKey] = useState('');
  const handleChange = (e) => {
    const { value } = e.target;
    setKey(value);
  };
  const disable = () => {
    if (key === keyForDisable) {
      disableCloudpayments();
    } else {
      sendNotification('error', 'Неверный ключ', 'Попробуйте еще раз');
    }
  };
  return (
    <Modal title="Отключение подписок Cloudpayments" footer={null} centered visible={visible} onCancel={hideFn}>
      <Container>
        <Title>
          Внимание! Это БОЛЬШАЯ КРАСНАЯ КНОПКА, которая отключает все активные подписки в
          Cloudpayments. Действие необратимо!
        </Title>
        <InputDescription>
          Введите ключ для отключения подписок. Если вы не знаете ключ, обратитесь к администратору.
          <br />
          Либо просто введите
          {' '}
          <b>{keyForDisable}</b>
        </InputDescription>
        <Input
          value={key}
          onChange={handleChange}
        />
        <Popconfirm
          title="Вы уверены, что хотите отключить все подписки?"
          onConfirm={disable}
          okText="Да"
          cancelText="Нет"
        >
          <BigRedButton>
            Отключить
          </BigRedButton>
        </Popconfirm>
      </Container>
    </Modal>
  );
};

export default connect(
  (props) => ({ ...props }),
  (dispatch) => ({ setKey: (d) => dispatch(privateKeySet(d)) }),
)(RsaPrivate);
