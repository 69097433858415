/* eslint-disable import/prefer-default-export */
import { FETCH_FUTURE_PAYMENTS, RECEIVE_FUTURE_PAYMENTS, RECEIVE_FUTURE_PAYMENTS_FAIL } from 'actions/constants';
import { proceedPlanned } from 'selectors';

export const payments = (state = { list: {} }, action) => {
  switch (action.type) {
    case FETCH_FUTURE_PAYMENTS:
      return { ...state, loading: true };
    case RECEIVE_FUTURE_PAYMENTS_FAIL:
      return { ...state, loading: false };
    case RECEIVE_FUTURE_PAYMENTS:
      return {
        list: {
          ...proceedPlanned(action.payments),
        },
        loading: false,
      };
    default:
      return state;
  }
};
