/* eslint-disable no-console */
import axios from 'axios';
import { getAuthToken } from 'selectors';
import store from 'store';

const prefix = '/api/v1';

axios.defaults.withCredentials = true;

export const Get = (url, options) => {
  const token = getAuthToken(store.getState());
  const authOptions = {
    ...options,
    headers: {
      'x-access-token': token,
    },
  };
  const Url = `${prefix}${url}`;
  return axios.get(Url, authOptions);
};

export const Post = (url, options) => {
  const token = getAuthToken(store.getState());
  const authOptions = {
    headers: {
      'x-access-token': token,
    },
  };
  const Url = `${prefix}${url}`;
  return axios.post(Url, options, authOptions);
};

export const Delete = (url, options) => {
  const token = getAuthToken(store.getState());
  const authOptions = {
    headers: {
      'x-access-token': token,
    },
  };
  const Url = `${prefix}${url}`;
  return axios.delete(Url, authOptions, options);
};

export const Put = (url, options) => {
  const token = getAuthToken(store.getState());
  const authOptions = {
    headers: {
      'x-access-token': token,
    },
  };
  const Url = `${prefix}${url}`;
  return axios.put(Url, options, authOptions);
};

export const Patch = (url, options) => {
  const token = getAuthToken(store.getState());
  const authOptions = {
    headers: {
      'x-access-token': token,
    },
  };
  const Url = `${prefix}${url}`;
  return axios.patch(Url, options, authOptions);
};
