/* eslint-disable react/jsx-filename-extension */
/* @flow */
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from 'App.jsx';
import { isProduction } from 'config';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import registerServiceWorker from 'registerServiceWorker';
import store, { history } from 'store';
import { APP_VERSION } from './constants';
import './index.css';

Sentry.init({
  dsn: 'https://017df88134014ff7bfa03c9759f173c6@o792360.ingest.sentry.io/5811085',
  environment: isProduction ? 'production' : 'development',
  integrations: [
    new Integrations.BrowserTracing({
      // Can also use reactRouterV4Instrumentation
      routingInstrumentation: Sentry.reactRouterV4Instrumentation(history),
    }),
  ],
  release: `donate_admin@${APP_VERSION}`,
  tracesSampleRate: 1.0,
});

const RenderNode = document.getElementById('root');

if (RenderNode) {
  ReactDOM.render(
    <Sentry.ErrorBoundary fallback="An error has occurred">
      <Provider store={store}>
        {/* <ConnectedRouter history={myHistory}> */}
        <App />
        {/* </ConnectedRouter> */}
      </Provider>
    </Sentry.ErrorBoundary>,
    RenderNode,
  );
}
registerServiceWorker();

if (module.hot && typeof module.hot.accept === 'function') {
  module.hot.accept();
}
