import { Content } from 'components/Modals/styled';
import styled from 'styled-components';

export const SaveButton = styled.button`
  margin-top: 30px;
  background-color: #0036ff;
  border-radius: 0;
  border: none;
  color: #fff;
  font-family: 'Fira Mono';
  font-size: 11px;
  padding: 12px 0 10px 0;
  width: 100%;
`;

export const CancelButton = styled.button`
  margin-top: 30px;
  background-color: #f1485d;
  border-radius: 0;
  border: none;
  color: #fff;
  font-family: 'Fira Mono';
  font-size: 11px;
  padding: 12px 0 10px 0;
  width: 100%;
`;

export const StyledContainer = styled.div`
`;

export const StyledContent = styled(Content)`
  overflow: hidden;
`;
