/* @flow */
/* eslint-disable camelcase */
import Auth from 'Auth';
import { Button } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: #f7f7f7;
  min-height: calc(100vh);
  display: flex;
  box-sizing: border-box;
`;

const CentredForm = styled.div`
  width: 440px;
  display: inline-block;
  height: auto;
  background: white;

  margin: auto;
  padding: 80px 80px 90px;
`;

const BaseInput = styled.input`
  font-size: 14px;
  width: 100%;
  border: 0;
  outline: 0;
  font-family: 'Graphik LC', sans-serif;
  font-weight: bold;
  color: black;
  line-height: 22px;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
    -webkit-text-fill-color: #333;
  }

  &::placeholder {
    color: #dfdfdf;
    font-weight: 100;
    letter-spacing: initial;
  }
`;

const SecuredInput = styled(BaseInput)`
  font-size: 14px;
  letter-spacing: 5px;
`;

const InputLine = styled.div`
  padding: 20px 0;
  display: flex;
  border-bottom: 1px solid #e5e5e5;
  align-items: center;
`;

const SignIn = styled(Button)`
  margin: 20px 0;

  font-family: 'Fira Mono', sans-serif;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;

  /* padding: 13px 0; */
  width: 100%;

  border-radius: 0;
  border: 0;

  background: #00c656;
  color: white;

  outline: 0;
  cursor: pointer;

  &.ant-btn-primary:hover,
  &.ant-btn-primary:focus {
    background-color: #64d093;
  }

  &.ant-btn-primary:active {
    background-color: #00c656;
  }
`;

class Login extends React.Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);

    this.state = {
      login: '',
      password: '',
      loading: false,
      code: '',
    };
  }

  static getDerivedStateFromProps(
    props: LoginProps,
  ) {
    if (['login_failure', 'logged_otp'].includes(props.loginStatus)) {
      return { loading: false };
    }
    return null;
  }

  login = () => {
    const {
      login,
      password,
    } = this.state;
    this.setState({ loading: true }, () => Auth.authenticate({
      password,
      login,
    }));
  };

  loginOtp = () => {
    const {
      login,
      password,
      code,
    } = this.state;
    Auth.authenticateOtp({
      login,
      password,
      code,
    });
  };

  reflectToState = (e: { target: { name: string, value: string } }) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onKeyPress = (event: Event) => {
    if (event.key === 'Enter') {
      this.login();
    }
  };

  renderLogin = () => {
    const {
      loading,
      login,
      password,
    } = this.state;
    return (
      <>
        <InputLine>
          <BaseInput
            name="login"
            type="text"
            placeholder="Логин"
            onChange={this.reflectToState}
            autocomplete="off"
            value={login}
            onKeyPress={this.onKeyPress}
          />
        </InputLine>
        <InputLine security>
          <SecuredInput
            name="password"
            type="password"
            placeholder="Пароль"
            onChange={this.reflectToState}
            value={password}
            autocomplete="off"
            isEmpty={password.length === 0}
            onKeyPress={this.onKeyPress}
          />
        </InputLine>
        <SignIn onClick={this.login} type="primary" loading={loading}>
          Войти
        </SignIn>
      </>
    );
  };

  renderOtp = () => {
    const {
      code,
      loading,
    } = this.state;
    return (
      <>
        <InputLine>
          <BaseInput
            name="code"
            type="text"
            placeholder="Код"
            onChange={this.reflectToState}
            autocomplete="off"
            value={code}
            onKeyPress={this.loginOtp}
          />
        </InputLine>
        <SignIn onClick={this.loginOtp} type="primary" loading={loading}>
          Отправить код
        </SignIn>
      </>
    );
  };

  render() {
    const { props } = this;
    const { from } = props?.location?.state || { from: { pathname: '/' } };
    const {
      isAuthenticated,
      isOtp,
      role,
    } = this.props;
    const redirectTo = role?.id === 4 ? '/mailings' : from;
    if (isAuthenticated) {
      return <Redirect to={redirectTo} />;
    }

    return (
      <Wrapper>
        <CentredForm>{isOtp ? this.renderOtp() : this.renderLogin()}</CentredForm>
      </Wrapper>
    );
  }
}

export default connect((state: FullStore): {
  isAuthenticated: boolean,
  loginStatus: string,
  isOtp: boolean,
} => ({
  isAuthenticated: !!state.user.username,
  loginStatus: state.user.status,
  isOtp: state.user.otpAuth,
  role: state.user.role,
}))(Login);
