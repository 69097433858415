export const { NODE_ENV, REACT_APP_APP_URL, REACT_APP_PUBLIC_KEY } = process.env;
export const isProduction = NODE_ENV === 'production';

export const APP_URL = REACT_APP_APP_URL || 'https://stage-donate.zona.media';
export const PUBLIC_KEY = REACT_APP_PUBLIC_KEY
  || `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDWK4hL+WoOFUZuxbdhjwsV6Ybu
KrOBX2ZoOyhvtsG2yPCo368TtYp16+bjwOy5eGA/wGT+s/5mjxtp704XOeJZRLYP
GmKU+RhxgWywZSjlaOZsaXHpiVnu9EVk7fj5ejCrCiF0gPK3ueVdImuc+DMvxYHx
LOQ2z196FK96RSseawIDAQAB
-----END PUBLIC KEY-----`;

const config = {
  // RECAPTCHA_PUBLIC: '6LfGPaAUAAAAAKahirpSryEn4qp8JDSz5OXlM8q3',
  RECAPTCHA_PUBLIC: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  APP_URL,
  PUBLIC_KEY,
  isProduction,
};

export default config;
