/* @flow */
/* eslint-disable camelcase */
import { fetchRecurrents } from 'actions';
import Recurrents from 'components/Recurrents';
import Totals from 'components/Totals';
import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getOffset } from 'utils/pagination';
import { Title } from './styled';

const Wrapper = styled.div`
  background-color: #f7f7f7;
`;

const Container = styled.div`
  background-color: #fff;
  margin: 0 150px;
  padding: 55px 80px 0;
  position: relative;
`;

class Recurrent extends React.Component<RecurrentProps, RecurrentState> {
  constructor(props: RecurrentProps) {
    super(props);
    this.state = {
      recurrents: [],
      limit: 50,
      offset: 0,
      count: 0,
    };
  }

  componentDidMount() {
    const { limit, offset, count } = this.state;
    const { dispatch } = this.props;
    dispatch(fetchRecurrents({ limit, offset, count }));
  }

  UNSAFE_componentWillReceiveProps(nextProps: RecurrentProps) {
    const { recurrents, count } = nextProps;
    this.setState(() => ({
      recurrents,
      count,
    }));
  }

  handlePageClick({ page, limit }: { page: number, limit: number }) {
    const { dispatch } = this.props;
    const offset = getOffset(page, limit);
    this.setState(
      () => ({
        offset,
      }),
      () => dispatch(fetchRecurrents({ limit, offset })),
    );
  }

  render() {
    const {
      recurrents, offset, limit,
    } = this.state;
    const { pageCount, count, loading } = this.props;
    return (
      <Wrapper>
        <Container>
          <Title>Подписки</Title>
          <Totals recurrents showStat />
          <Recurrents
            recurrents={recurrents}
            pageCount={pageCount}
            count={count}
            offset={offset}
            limit={limit}
            loading={loading}
            changePage={(data: { selected: number }) => this.handlePageClick(data)}
          />
        </Container>
      </Wrapper>
    );
  }
}

export default connect((state: FullStore): {
  recurrents: RecurrentType[],
  pageCount: number,
  count: number,
  privateKey: string,
  loading: boolean,
} => ({
  recurrents: state.recurrents.list,
  pageCount: state.recurrents.pageCount,
  count: state.recurrents.count,
  privateKey: state.privateKey.value,
  loading: state.recurrents.loading,
}))(Recurrent);
