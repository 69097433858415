import { DonorItem } from 'containers/styled';
import styled from 'styled-components';

export const ContentContainer = styled.div`
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 30px;
`;

export const AlertContainer = styled.div`
  left: -20px;
  top: 0px;
  position: absolute;

  svg {
    margin-right: 0;
  }
`;

export const StyledDonorItem = styled(DonorItem)`
  overflow: visible;
`;

export const Months = styled.span`
  color: #00c656;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 10px;
  left: 40px;
`;
