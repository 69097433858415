/* @flow */
/* eslint-disable no-console */
import * as Sentry from '@sentry/react';
import { PUBLIC_KEY } from 'config';
import type { Dispatch } from 'redux';
import { sendNotification } from 'utils/notification';
import {
 Delete, Get, Patch, Post, Put,
} from 'utils/requester';

import {
  CLEAR_SEARCH,
  FAIL,
  FAIL_ADD_HOLD_EMAILS,
  FAIL_REFUND_ORDER,
  FETCH_ERRORS,
  FETCH_FUTURE_PAYMENTS,
  FETCH_ORDERS,
  FETCH_RECURRENTS,
  FETCH_TOTALS,
  LOAD_SEARCH_END,
  LOAD_SEARCH_REQUEST,
  PRIVATE_KEY_SET,
  RECEIVE_CAMPAIGNS,
  RECEIVE_CUSTOM_ORDER,
  RECEIVE_ERRORS,
  RECEIVE_FUTURE_PAYMENTS,
  RECEIVE_FUTURE_PAYMENTS_FAIL,
  RECEIVE_HOLD_EMAILS,
  RECEIVE_ORDERS,
  RECEIVE_RECURRENTS,
  RECEIVE_SEARCH,
  RECEIVE_SUBSCRIBERS,
  RECEIVE_TOTALS,
  RECEIVE_UNSUBSCRIBERS,
  SET_PUBLIC_KEY,
  SUCCESS,
  SUCCESS_ADD_HOLD_EMAIL,
  SUCCESS_ADD_HOLD_EMAILS,
  SUCCESS_REFUND_ORDER,
  USER_LOGIN,
  USER_LOGIN_FAILURE,
  USER_LOGIN_OTP,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_SESSION_CHECK,
  USER_SESSION_EXPIRED,
} from 'actions/constants';

const InitialQuery: fetchData = {
  dateRange: { startDate: 'null', endDate: 'null' },
  offset: 0,
  limit: 30,
};

export const receiveOrders = (orders: {
  orders: OrderType[],
  pageCount: number,
  count: number,
}) => ({
  type: RECEIVE_ORDERS,
  ...orders,
});

export const userLoginFailure = () => ({ type: USER_LOGIN_FAILURE });

export const userSessionCheck = () => ({ type: USER_SESSION_CHECK });

export const userSessionExpired = () => ({ type: USER_SESSION_EXPIRED });

const handleLogout = (e, dispatch) => {
  if (e.message.includes('401')) {
      dispatch(userSessionExpired());
    }
};

export const fetchOrders = (query: fetchData = InitialQuery) => async (
  dispatch: Dispatch<*>,
) => {
  try {
    dispatch({ type: FETCH_ORDERS });
    const { startDate, endDate } = query.dateRange;
    const { offset, limit } = query;
    const start = startDate === null ? NaN : startDate;
    const end = endDate === null ? NaN : endDate;
    const fetchOrdersUrl = `/admin/order?offset=${offset}&limit=${limit}&startDate=${new Date(
      start,
    ).getTime()}&endDate=${new Date(end).getTime()}`;
    const res: {
      data: { orders: Array<OrderType>, pageCount: number },
    } = await Get(fetchOrdersUrl);
    dispatch(receiveOrders(res.data));
  } catch (e) {
    Sentry.captureException(e);
    console.error('Error in fetchOrders', e);
    handleLogout(e, dispatch);
  }
};

export const createOrder = (order: OrderType, username: string) => async (
  dispatch: Dispatch<*>,
) => {
  try {
    await Post('/admin/order', {
      data: { ...order, email: username },
    });
    dispatch(fetchOrders());
  } catch (e) {
    Sentry.captureException(e);
    console.error('Error in createOrder', e);
    handleLogout(e, dispatch);
  }
};

export const receiveRecurrents = ({
  recurrents,
  pageCount,
  count,
}: {
  recurrents: RecurrentType[],
  pageCount: number,
  count: number,
}) => ({
  type: RECEIVE_RECURRENTS,
  recurrents,
  pageCount,
  count,
});

export const fetchRecurrents = ({ offset, limit }: fetchData) => async (
  dispatch: Dispatch<*>,
) => {
  try {
    dispatch({ type: FETCH_RECURRENTS });
    const recurrents = await Get(
      `/admin/recurrent?offset=${offset}&limit=${limit}`,
    );
    dispatch(receiveRecurrents(recurrents.data));
  } catch (e) {
    Sentry.captureException(e);
    console.error('Error in fetchRecurrents', e);
    handleLogout(e, dispatch);
  }
};

export const setFuturePayments = (payments: {
  [key: string]: Recurrent[],
}) => ({
  type: RECEIVE_FUTURE_PAYMENTS,
  payments,
});

export const fetchFuturePayments = ({ startDate, endDate } = {}) => async (
  dispatch: Dispatch<*>,
) => {
  try {
    dispatch({ type: FETCH_FUTURE_PAYMENTS });
    const res = await Get('/admin/future/list', {
      params: { startDate, endDate },
    });
    dispatch(setFuturePayments(res.data.payments));
  } catch (e) {
    dispatch({ type: RECEIVE_FUTURE_PAYMENTS_FAIL });
    Sentry.captureException(e);
    console.error(e);
    sendNotification(
      'error',
      'Future recurrents',
      'Не удалось получить запланированные платежи',
    );
    handleLogout(e, dispatch);
  }
};

export const clearSearch = () => ({
  type: CLEAR_SEARCH,
});

export const receiveSearch = (result: Object, email: string) => ({
  type: RECEIVE_SEARCH,
  result,
  email,
});

export const loadSearchRequest = (str: string) => ({
  type: LOAD_SEARCH_REQUEST,
  payload: str,
});

export const loadSearchEnd = () => ({
  type: LOAD_SEARCH_END,
});

export const fetchSearch = (str: string, searchType: string) => async (
  dispatch: Dispatch<*>,
) => {
  try {
    dispatch(clearSearch());
    dispatch(loadSearchRequest(str));
    const result = await Post('/admin/search', { str, type: searchType });
    dispatch(receiveSearch(result.data, str));
  } catch (e) {
    Sentry.captureException(e);
    console.error('Error in fetchSearch', e);
    handleLogout(e, dispatch);
  } finally {
    dispatch(loadSearchEnd());
  }
};

export const receiveTotals = (data: Totals) => ({
  type: RECEIVE_TOTALS,
  data,
});

export const receiveErrors = (data: {
  errors: OrderType[],
  pageCount: number,
  count: number,
}) => ({
  type: RECEIVE_ERRORS,
  ...data,
});

export const fetchTotals = () => async (dispatch: Dispatch<*>) => {
  try {
    dispatch({ type: FETCH_TOTALS });
    const orderTotals = await Get('/admin/totals');
    dispatch(receiveTotals(orderTotals.data));
  } catch (e) {
    Sentry.captureException(e);
    console.error('Error in fetchTotals', e);
    handleLogout(e, dispatch);
  }
};

export const fetchErrors = ({ dateRange, offset, limit }: fetchData) => async (
  dispatch: Dispatch<*>,
) => {
  try {
    dispatch({ type: FETCH_ERRORS });
    const { startDate, endDate } = dateRange;
    const start = startDate === null ? NaN : startDate;
    const end = endDate === null ? NaN : endDate;
    const errors = await Get(
      `/admin/error?offset=${offset}&limit=${limit}&startDate=${new Date(
        start,
      ).getTime()}&endDate=${new Date(end).getTime()}`,
    );
    dispatch(receiveErrors(errors.data));
  } catch (e) {
    Sentry.captureException(e);
    console.error('Error in fetchErrors', e);
    handleLogout(e, dispatch);
  }
};

export const privateKeySet = (privateKey: string) => ({
  type: PRIVATE_KEY_SET,
  privateKey,
});

export const receiveCustomOrder = (order: OrderType) => ({
  type: RECEIVE_CUSTOM_ORDER,
  order,
});

export const fetchCustomOrder = () => async (dispatch: Dispatch<*>) => {
  try {
    const order = await Get('/admin/order/custom/last');
    if (order && order.status <= 300 && order.status >= 200) {
      dispatch(receiveCustomOrder(order.data));
    } else {
      throw new Error(order.status);
    }
  } catch (e) {
    Sentry.captureException(e);
    console.error('Error in fetchCustomOrder', e);
    handleLogout(e, dispatch);
  }
};

export const cancelSubscription = (
  id: number,
  email: string,
  type: string,
) => async (dispatch: Dispatch<*>) => {
  try {
    await Post(`/admin/recurrent/${id}/cancel`, { cancel: true });
    dispatch(fetchSearch(email, type));
  } catch (e) {
    Sentry.captureException(e);
    console.error('Error in cancelSubscription', e);
    handleLogout(e, dispatch);
  }
};

export const changeSubscriptionDate = (
  date: number,
  id: number,
  email: string,
  type: string,
) => async (dispatch: Dispatch<*>) => {
  try {
    await Post(`/admin/recurrent/${id}/update_date`, {
      date: new Date(date).getTime(),
    });
    dispatch(fetchSearch(email, type));
  } catch (e) {
    Sentry.captureException(e);
    console.error('Error in changeSubscriptionDate', e);
    handleLogout(e, dispatch);
  }
};

export const changePaymentSum = (
  sum: string,
  id: number,
  email: string,
  type: string,
) => async (dispatch: Dispatch<*>) => {
  try {
    const updatedRecurrent = await Post(`/admin/recurrent/${id}/update_sum`, {
      sum,
    });
    if (updatedRecurrent && updatedRecurrent.status === 200) {
      dispatch(fetchSearch(email, type));
    }
  } catch (e) {
    Sentry.captureException(e);
    console.error('Error in update_sum', e);
    handleLogout(e, dispatch);
  }
};

export const setPublicKey = (key: string) => ({ type: SET_PUBLIC_KEY, key });

export const userLogout = () => ({ type: USER_LOGOUT });

export const userLogIn = () => ({ type: USER_LOGIN });

export const userLoginOtp = (data) => ({ type: USER_LOGIN_OTP, data });
export const userLoginSuccess = (user: User) => async (
  dispatch: Dispatch<*>,
) => {
  try {
    dispatch({ type: USER_LOGIN_SUCCESS, user });
    dispatch(setPublicKey(PUBLIC_KEY));
  } catch (e) {
    Sentry.captureException(e);
    console.error('Error in userLoginSuccess', e);
    handleLogout(e, dispatch);
  }
};

export const attachOtp = (code: number) => async (dispatch: Dispatch<*>) => {
  try {
    const {
      data: { user },
    } = await Post('/auth/attach', { code });
    dispatch(userLoginSuccess(user));
    sendNotification('success', '2FA success', 'Двухфакторка успешно включена');
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
    sendNotification('error', '2FA error', 'Не получилось привязать');
    handleLogout(e, dispatch);
  }
};

export const detachOtp = () => async (dispatch: Dispatch<*>) => {
  try {
    const {
      data: { user },
    } = await Post('/auth/detach');
    dispatch(userLoginSuccess(user));
    sendNotification('success', '2FA detach', 'Двухфакторка успешно сброшена');
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
    sendNotification('error', '2FA detach', 'Не получилось сбросить');
    handleLogout(e, dispatch);
  }
};

export const enableOtp = (id?: number) => async (dispatch: Dispatch<*>) => {
  try {
    const {
      data: { user },
    } = await Post('/auth/enable', { id });
    dispatch(userLoginSuccess(user));
    sendNotification('success', '2FA enable', 'Двухфакторка успешно включена');
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
    sendNotification('error', '2FA enable', 'Не получилось включить');
    handleLogout(e, dispatch);
  }
};

export const disableOtp = (id?: number) => async (dispatch: Dispatch<*>) => {
  try {
    const {
      data: { user },
    } = await Post('/auth/disable', { id });
    dispatch(userLoginSuccess(user));
    sendNotification(
      'success',
      '2FA disable',
      'Двухфакторка успешно выключена',
    );
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
    sendNotification('error', '2FA disable', 'Не получилось выключить');
    handleLogout(e, dispatch);
  }
};

export const receiveHoldEmails = (emails: Array<EmailType>) => ({
  type: RECEIVE_HOLD_EMAILS,
  payload: { emails, length: emails.length },
});

export const fetchHoldEmails = () => async (dispatch: Dispatch<*>) => {
  try {
    const response: { data: Array<EmailType> } = await Get('/admin/email/hold');
    dispatch(receiveHoldEmails(response.data));
  } catch (e) {
    Sentry.captureException(e);
    console.error('Error in fetchHoldEmails', e);
    handleLogout(e, dispatch);
  }
};

export const successAddHoldEmails = (emails: string[]) => ({
  type: SUCCESS_ADD_HOLD_EMAILS,
  payload: { emails },
});

export const successAddHoldEmail = (email: string) => ({
  type: SUCCESS_ADD_HOLD_EMAIL,
  payload: { email },
});

export const failAddHoldEmail = (errorMsg: string) => ({
  type: FAIL_ADD_HOLD_EMAILS,
  payload: { errorMsg },
});

export const addHoldEmail = (emails: string) => async (
  dispatch: Dispatch<*>,
) => {
  try {
    const response: { data: Array<string> } = await Post('/admin/email/hold', {
      emails,
    });
    dispatch(successAddHoldEmails(response.data));
  } catch (e) {
    Sentry.captureException(e);
    let errMsg = e.message;
    if (e.response && e.response.data) errMsg = e.response.data.message;
    dispatch(failAddHoldEmail(errMsg));
    handleLogout(e, dispatch);
  }
};

export const removeHoldEmail = (id: number) => async (dispatch) => {
  try {
    await Delete(`/admin/email/hold/${id}`);
  } catch (e) {
    Sentry.captureException(e);
    console.error('Error in removeHoldEmail', e);
    handleLogout(e, dispatch);
  }
};

export const changeHoldEmailConditions = (id: number, conditions: Object) => async (dispatch) => {
  try {
    const { data } = await Patch(`/admin/email/hold/${id}`, conditions);
    const { email } = data;
    dispatch(successAddHoldEmail(email));
  } catch (e) {
    Sentry.captureException(e);
    console.error('Error in changeHoldEmailConditions', e);
    handleLogout(e, dispatch);
  }
};

export const successRefundOrder = (order: OrderType) => ({
  type: SUCCESS_REFUND_ORDER,
  payload: { order },
});

export const failRefundOrder = (orderId: string) => ({
  type: FAIL_REFUND_ORDER,
  payload: { orderId },
});

export const refundOrder = (orderId: string) => async (
  dispatch: Dispatch<*>,
) => {
  try {
    const res = await Put(`/admin/order/${orderId}/refund`);
    dispatch(successRefundOrder(res.data));
  } catch (e) {
    Sentry.captureException(e);
    dispatch(failRefundOrder(orderId));
    handleLogout(e, dispatch);
  }
};

export const receiveSubscribers = (emails: SubscribersType, region) => ({
  type: RECEIVE_SUBSCRIBERS + SUCCESS,
  emails,
  region,
});

export const getSubscribers = (region) => async (dispatch: Dispatch<*>) => {
  try {
    const EmailsList = await Get(`/admin/email/subscribers?region=${region}`);
    dispatch(receiveSubscribers(EmailsList.data, region));
  } catch (e) {
    Sentry.captureException(e);
    dispatch({ type: RECEIVE_SUBSCRIBERS + FAIL });
    handleLogout(e, dispatch);
  }
};

export const receiveUbsubscribers = (unsubscribers: SubscribersType[], region) => ({
  type: RECEIVE_UNSUBSCRIBERS,
  unsubscribers,
  region,
});

export const getUnsubscribers = (region) => async (dispatch: Dispatch<*>) => {
  try {
    const unsubscribers = await Get(`/admin/email/unsubscribers?region=${region}`);
    dispatch(receiveUbsubscribers(unsubscribers.data, region));
  } catch (e) {
    Sentry.captureException(e);
    console.error('Failed to get ubsubscribers', e);
    sendNotification(
      'error',
      'Ошибка получения отписавшихся',
      'Что-то пошло не так :(',
    );
    handleLogout(e, dispatch);
  }
};

export const receiveCampaigns = (campaigns: CampaignType[], region) => ({
  type: RECEIVE_CAMPAIGNS,
  campaigns,
  region,
});

export const getCampaigns = (region) => async (dispatch: Dispatch<*>) => {
  try {
    const campaigns = await Get(`/admin/email/campaigns?region=${region}`);
    if (campaigns && campaigns.status === 200) {
      dispatch(receiveCampaigns(campaigns.data, region));
    }
  } catch (e) {
    Sentry.captureException(e);
    console.error('Error in getCampaigns', e);
    sendNotification(
      'error',
      'Ошибка получения кампаний',
      'Что-то пошло не так :(',
    );
    handleLogout(e, dispatch);
  }
};

export const createMailCampaign = ({
  text,
  campaign,
  subject,
  list,
  region,
}: {
  text: string,
  campaign: string,
  subject: string,
  list: string[],
  region: string,
}) => async (dispatch: Dispatch<*>) => {
  try {
    const newCampaign = await Post('/admin/email/campaigns', {
      subject,
      text,
      campaign,
      list,
      region,
    });
    if (newCampaign && newCampaign.status === 200) {
      sendNotification('success', 'Успешно', 'Кампания успешно создана!');
      dispatch(getCampaigns(region));
    } else {
      throw new Error(newCampaign.status);
    }
  } catch (e) {
    Sentry.captureException(e);
    console.error(e);
    switch (e.response.status) {
      case 436:
        sendNotification(
          'error',
          'Ошибка создания кампании',
          'Есть такая компания!',
        );
        break;
      case 437:
        sendNotification(
          'error',
          'Ошибка создания кампании',
          'Отсутствуют нужные символы!',
        );
        break;
      case 411:
        sendNotification(
          'error',
          'Ошибка создания кампании',
          'Отсутствует одно из необходимых полей!',
        );
        break;
      case 401:
        dispatch(userSessionExpired());
        break;
      default:
        sendNotification(
          'error',
          'Ошибка создания кампании',
          'Что-то пошло не так!',
        );
    }
    handleLogout(e, dispatch);
  }
};

export const disableCloudpayments = () => async () => {
  try {
    const res = await Post('/admin/recurrent/cloudpayments/disable');
    if (res && res.status === 200) {
      sendNotification('success', 'Успешно', 'Cloudpayments отключен');
    }
  } catch (e) {
    Sentry.captureException(e);
    console.error('Error in disableCloudpayments', e);
    sendNotification('error', 'Ошибка отключения Cloudpayments', 'Срочно писать Косте или Антону!');
  }
};
