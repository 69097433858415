import { StatsIcon } from 'Icons';
import { Divider, Input, Statistic } from 'antd';
import styled from 'styled-components';

export const StyledDivider = styled(Divider)`
  height: 100%;
`;

export const DatesContainer = styled.div`
  height: 50%;
  margin-top: auto;
`;

export const DatesButton = styled.button`
  border: none;
  background-color: transparent;
  color: #0036ff;
`;

export const StyledStatistic = styled(Statistic)`
  cursor: pointer;
  display: flex;
  flex-direction: column-reverse;
  & .ant-statistic-content-value, .ant-statistic-content-suffix {
    font: bold 16px 'Graphik LC', sans-serif;
  }
  & .ant-statistic-title {
    font: normal 11px 'Fira Mono', sans-serif;
    color: #000;
  }
`;

export const TotalsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 27px;
`;

export const TotalsContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const CountersContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

export const TotalsItem = styled.div`
  border-right: 1px solid #e5e5e5;
  color: #222;
  font: 11px 'Fira Mono', sans-serif;
  padding: 7px 20px;

  &:first-child {
    padding-left: 0px;
  }

  &:last-child {
    border: none;
  }

  b {
    color: #333;
    font: bold 16px 'Graphik LC', sans-serif;
  }
`;

export const Form = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  width: 30%;
`;

export const Search = styled(Input)`
  & .ant-input {
    font: normal 13px 'Fira Mono', sans-serif;
    outline: none;
  }

  &::placeholder {
    font-size: 11px;
  }
`;

export const StatButton = styled.button`
  align-self: center;
  border-radius: 0;
  border: solid 1px #e5e5e5;
  color: #0036ff;
  cursor: pointer;
  font-family: FiraMono;
  font-size: 11px;
  height: 40px;
  margin-left: 40px;
  width: 140px;
`;

export const StyledStatsIcon = styled(StatsIcon)`
  width: 10px;
  fill: #0036ff;
`;

export const CloseIconWrapper = styled.div`
  cursor: pointer;
  right: 15px;
  top: 14px;
  position: absolute;
  svg {
    fill: #333;
  }
`;
