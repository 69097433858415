/* @flow */
import {
  attachOtp,
  detachOtp,
  disableOtp,
  enableOtp,
} from 'actions';
import { Input } from 'antd';
import { StyledModal, Title } from 'components/Modals/styled';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  AttachButton,
  ButtonsContainer,
  DetachButton,
  DisableButton,
  EnableButton,
  Img,
  InputContainer,
} from './styled';

type Props = {
  hideFn: () => void,
  qrCode: string,
  otpEnabled: boolean,
  otpAttached: boolean,
  visible: boolean,
  dispatch(Object): void,
};

const formatCode = (e) => {
  const { value } = e.target;
  return value.replace(/\D/, '');
};

const AttachOtp = (props: Props) => {
  const {
    hideFn, qrCode, otpEnabled, otpAttached, visible, dispatch,
  } = props;
  const [code, handleChangeCode] = useState('');

  return (
    <StyledModal
      title="Двухфакторная авторизация"
      centered
      visible={visible}
      footer={null}
      onCancel={hideFn}
    >
      <Title>Сканируем QRCode приложением Google Authenticator или Authy</Title>
      <Img src={qrCode} alt="qrCode" />
      <InputContainer>
        <Input
          placeholder="Введи код здесь"
          allowClear
          onChange={(e) => handleChangeCode(formatCode(e))}
          value={code}
        />
        <AttachButton
          disabled={otpAttached}
          onClick={() => {
            dispatch(attachOtp(code));
            handleChangeCode('');
          }}
        >
          Привязать и включить
        </AttachButton>
      </InputContainer>
      <ButtonsContainer>
        <DetachButton onClick={() => dispatch(detachOtp())}>
          Сбросить
        </DetachButton>
        <EnableButton
          disabled={otpEnabled || !otpAttached}
          onClick={() => dispatch(enableOtp())}
        >
          Включить
        </EnableButton>
        <DisableButton
          disabled={!otpEnabled}
          onClick={() => dispatch(disableOtp())}
        >
          Выключить
        </DisableButton>
      </ButtonsContainer>
    </StyledModal>
  );
};

export default connect((state) => ({
  qrCode: state.user?.qrCode,
  otpEnabled: state.user?.otpEnabled,
  otpAttached: state.user?.otpAttached,
}))(AttachOtp);
