import { changeHoldEmailConditions, removeHoldEmail } from 'actions';
import {
 Checkbox, Col, Divider, Modal, Row,
} from 'antd';
import { Title } from 'components/Modals/styled';
import React from 'react';
import { connect } from 'react-redux';
import { decrypt } from 'utils';
import {
  CancelButton,
  SaveButton,
  StyledContainer,
  StyledContent,
} from './styled';

type RemoveHoldEmailProps = {
  privateKey: string,
  removeEmail(id): void,
  changeEmail(number, Object): void,
  hideFn(): void,
  email: Object,
  visible: boolean,
  holdService: boolean,
  holdSuccess: boolean,
};

class RemoveHoldEmail extends React.Component<RemoveHoldEmailProps> {
  constructor(props) {
    super(props);
    this.state = {
      holdService: props.email?.holdService,
      holdSuccess: props.email?.holdSuccess,
    };
  }

  removeFromHold = () => {
    const { email: { id }, hideFn, removeEmail } = this.props;
    removeEmail(id);
    hideFn();
  };

  changeService = (e) => {
    this.setState({ holdService: e.target.checked });
  };

  changeSuccess = (e) => {
    this.setState({ holdSuccess: e.target.checked });
  };

  changeConditions = () => {
    const { changeEmail, email: { id }, hideFn } = this.props;
    changeEmail(id, this.state);
    hideFn();
  };

  render() {
    const {
      email, hideFn, privateKey, visible,
    } = this.props;
    const { holdService, holdSuccess } = this.state;
    return (
      <Modal centered footer={null} visible={visible} onCancel={hideFn}>
        <StyledContainer>
          <StyledContent>
            <Title>Изменение условий холда</Title>
            <Row>
              <Col>Почта:</Col>
              <Col>{decrypt(email?.email, privateKey)}</Col>
            </Row>
            <Divider />
            <Row justify="space-between">
              <Col>Ошибки:</Col>
              <Col>
                <Checkbox checked={holdService} onChange={this.changeService} />
              </Col>
            </Row>
            <Divider />
            <Row justify="space-between">
              <Col>Спасибо:</Col>
              <Col>
                <Checkbox
                  checked={holdSuccess}
                  onChange={this.changeSuccess}
                />
              </Col>
            </Row>
            <Divider />
            <SaveButton onClick={this.changeConditions}>Сохранить</SaveButton>
            <CancelButton onClick={this.removeFromHold}>Удалить</CancelButton>
          </StyledContent>
        </StyledContainer>
      </Modal>
    );
  }
}

export default connect(
  (state) => ({
    privateKey: state.privateKey.value,
  }),
  (dispatch) => ({
    removeEmail: (id) => dispatch(removeHoldEmail(id)),
    changeEmail: (id, conditions) => dispatch(changeHoldEmailConditions(id, conditions)),
  }),
)(RemoveHoldEmail);
