/* eslint-disable no-debugger */
/* @flow */
import * as actions from 'actions/constants';
import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import type {
  Order, Recurrent, SearchType, Totals, User,
} from 'types';
import { orders } from './orders';
import { payments } from './payments';
import { user } from './user';

declare type Action = {
  +type: string,
  pageCount?: number,
  orders?: Order[],
  order?: Order,
  recurrents?: Recurrent[],
  result?: SearchType,
  email?: string,
  errors?: Order[],
  user?: User,
  data?: Totals,
  privateKey?: string,
  payload?: {
    id?: number,
    emails?: Array<EmailType> | string,
    errorMsg?: string,
  },
  unsubscribers: string[],
};

const recurrents = (state = { list: [] }, action: Action) => {
  switch (action.type) {
    case actions.FETCH_RECURRENTS:
      return {
        ...state,
        loading: true,
      };
    case actions.RECEIVE_RECURRENTS:
      return {
        list: action.recurrents,
        pageCount: action.pageCount,
        count: action.count,
        loading: false,
      };
    case actions.USER_LOGOUT:
      return { list: [] };
    default:
      return state;
  }
};

const search = (
  state = {
    recurrentOrders: [],
    transactionalOrders: [],
    recurrents: [],
    isFetching: false,
    query: '',
  },
  action: Action,
) => {
  switch (action.type) {
    case actions.RECEIVE_SEARCH:
      return {
        ...state,
        ...action.result,
        query: action.email,
      };
    case actions.USER_LOGOUT:
      return {
        ...state,
        recurrentOrders: [],
        transactionalOrders: [],
        recurrents: [],
        query: '',
      };
    case actions.CLEAR_SEARCH:
      return {
        ...state,
        recurrentOrders: [],
        transactionalOrders: [],
        recurrents: [],
        query: '',
      };
    case actions.LOAD_SEARCH_REQUEST:
      return { ...state, isFetching: true, query: action.payload };
    case actions.LOAD_SEARCH_END:
      return { ...state, isFetching: false };

    case actions.TRY_REFUND_ORDER: {
      const { orderId } = action.payload;

      return {
        ...state,
        transactionalOrders: state.transactionalOrders.map((order) => {
          if (order.id === orderId) {
            return {
              ...order,
              clientMetaProcessing: true,
            };
          }
          return order;
        }),
        recurrentOrders: state.recurrentOrders.map((order) => {
          if (order.id === orderId) {
            return {
              ...order,
              clientMetaProcessing: true,
            };
          }
          return order;
        }),
      };
    }

    case actions.SUCCESS_REFUND_ORDER: {
      const { id } = action.payload.order;

      return {
        ...state,
        transactionalOrders: state.transactionalOrders.map((order) => {
          if (order.id === id) {
            return {
              ...order,
              ...action.payload.order,
              clientMetaRefunedFailed: false,
              clientMetaProcessing: false,
            };
          }
          return order;
        }),
        recurrentOrders: state.recurrentOrders.map((order) => {
          if (order.id === id) {
            return {
              ...order,
              ...action.payload.order,
              clientMetaRefunedFailed: false,
              clientMetaProcessing: false,
            };
          }
          return order;
        }),
      };
    }

    case actions.FAIL_REFUND_ORDER: {
      const { orderId } = action.payload;

      return {
        ...state,
        transactionalOrders: state.transactionalOrders.map((order) => {
          if (order.id === orderId) {
            return {
              ...order,
              clientMetaRefunedFailed: true,
              clientMetaProcessing: false,
            };
          }
          return order;
        }),
        recurrentOrders: state.recurrentOrders.map((order) => {
          if (order.id === orderId) {
            return {
              ...order,
              clientMetaRefunedFailed: true,
              clientMetaProcessing: false,
            };
          }
          return order;
        }),
      };
    }
    default:
      return state;
  }
};

const errors = (state = { list: [] }, action: Action) => {
  switch (action.type) {
    case actions.FETCH_ERRORS:
      return {
        ...state,
        loading: true,
      };
    case actions.RECEIVE_ERRORS:
      return {
        list: action.errors,
        pageCount: action.pageCount,
        count: action.count,
        loading: false,
      };
    default:
      return state;
  }
};

const totals = (state = {}, action: Action) => {
  switch (action.type) {
    case actions.FETCH_TOTALS:
      return {
        ...state,
        loading: true,
      };
    case actions.RECEIVE_TOTALS:
      return { ...action.data, loading: false };
    case actions.USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

const privateKey = (state = {}, action: Action) => {
  switch (action.type) {
    case actions.PRIVATE_KEY_SET:
      return { value: action.privateKey };
    case actions.USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

const holdEmails = (
  state = { addNewEmailPending: false, errorMsg: '' },
  action: Action,
) => {
  switch (action.type) {
    case actions.RECEIVE_HOLD_EMAILS:
      return {
        ...state,
        emails: action.payload.emails,
      };
    case actions.REMOVE_HOLD_EMAIL: {
      const { id } = action.payload;
      return {
        ...state,
        emails: state.emails.filter((email) => email.id !== id),
      };
    }
    case actions.ADD_HOLD_EMAILS:
      return {
        ...state,
        errorMsg: action.payload.errorMsg,
        addNewEmailPending: true,
      };
    case actions.SUCCESS_ADD_HOLD_EMAILS:
      return {
        ...state,
        errorMsg: action.payload.errorMsg,
        emails: [...state.emails, ...action.payload.emails],
        addNewEmailPending: false,
      };
    case actions.SUCCESS_ADD_HOLD_EMAIL:
      return {
        ...state,
        emails: state.emails.map((email) => {
          if (email.id === action.payload.email.id) {
            return action.payload.email;
          }
          return email;
        }),
      };
    case actions.FAIL_ADD_HOLD_EMAILS:
      return {
        ...state,
        errorMsg: action.payload.errorMsg,
        addNewEmailPending: false,
      };
    default:
      return state;
  }
};

const publicKey = (
  state = null,
  { type, key }: { +type: string, key: string },
) => {
  switch (type) {
    case actions.SET_PUBLIC_KEY:
      return key;
    case actions.USER_LOGOUT:
      return null;
    default:
      return state;
  }
};

const mailLists = (state = { ru: {}, ca: {}, by: {} }, action) => {
  switch (action.type) {
    case actions.RECEIVE_SUBSCRIBERS + actions.SUCCESS:
      return {
        ...state,
        [action.region]: {
          ...state[action.region],
          ...action.emails,
        },
      };
    case actions.RECEIVE_UNSUBSCRIBERS:
      return {
        ...state,
        [action.region]: {
          ...state[action.region],
          unsubscribers: action.unsubscribers,
        },
      };
    case actions.RECEIVE_CAMPAIGNS:
      return {
        ...state,
        [action.region]: {
          ...state[action.region],
          campaigns: action.campaigns,
        },
      };
    default:
      return state;
  }
};

export default combineReducers({
  orders,
  recurrents,
  payments,
  search,
  errors,
  totals,
  privateKey,
  publicKey,
  user,
  holdEmails,
  mailLists,
  routing: routerReducer,
});
