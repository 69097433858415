import { FETCH_ORDERS, RECEIVE_CUSTOM_ORDER, RECEIVE_ORDERS } from 'actions/constants';

export const orders = (state = { list: [] }, action: Action) => {
  switch (action.type) {
    case FETCH_ORDERS:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_ORDERS:
      return {
        ...state,
        list: action.orders || [],
        pageCount: action.pageCount,
        count: action.count,
        loading: false,
      };
    case RECEIVE_CUSTOM_ORDER:
      return {
        ...state,
        lastPayment: action.order,
        loading: false,
      };
    default:
      return state;
  }
};

export default orders;
