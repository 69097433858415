/* @flow */
/* eslint-disable camelcase */
import { ArrowLeftOutlined, ArrowRightOutlined, SearchOutlined } from '@ant-design/icons';
import { clearSearch, fetchOrders, fetchTotals } from 'actions';
import { Copy } from 'components/Copy';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { encrypt } from 'utils';
import { toSearch } from 'utils/link';
import {
  CountersContainer,
  DatesButton,
  DatesContainer,
  Form,
  Search,
  StyledDivider,
  StyledStatistic as Statistic,
  TotalsContainer,
  TotalsWrapper,
} from './styled';

const isEncrypted = (str) => str.length > 100;
const isEmail = (str) => /(.+)@(.+){2,}\.(.+){2,}/.test(str);
const isLast4 = (str) => /^\d{4}$/.test(str);
const getCeilSum = (sum) => sum?.toString().split('.')[0];

type TotalsType = {
  orders_usd: number,
  orders_rub: number,
  recurrents_total: number,
  orders_count: number,
  recurrents_total_sum: number,
  orders_rub_total: number,
  donors: number,
  errors_total_rub: number,
  loading: boolean,
  ordersTotal: number,
  recurrentsTotalSum: number,
  suffix: string,
};

type TotalsProps = {
  totals: TotalsType,
  search: {
    query?: string,
  },
  publicKey: {
    data: string,
  },
  privateKey: string,
  plannedTotals: {
    planned?: number,
    actual?: number,
    failed?: number,
  },
  dispatch(Object): void,
  nextDay: () => void,
  previousDay: () => void,
  history: string[],
  errors: boolean,
  recurrents: boolean,
  plans: number,
  showStat: boolean,
};

type TotalsState = {
  query: string,
  totals: TotalsType,
  search: SearchType,
};

const Counters = ({
  ordersTotal,
  recurrentsTotalSum,
  donors,
  recurrents_total,
  loading,
  suffix,
  }: TotalsType) => (
    <TotalsContainer>
      <Copy text={ordersTotal}>
        <Statistic
          title={`Всего собрано ${suffix}`}
          value={ordersTotal}
          loading={loading}
          suffix={suffix}
        />
      </Copy>
      <StyledDivider type="vertical" />
      <Copy text={recurrentsTotalSum}>
        <Statistic
          title="Собирается подписками"
          value={recurrentsTotalSum}
          loading={loading}
          suffix={suffix}
        />
      </Copy>
      <StyledDivider type="vertical" />
      <Copy text={donors}>
        <Statistic title=" Донора(ов)" value={donors} loading={loading} />
      </Copy>
      <StyledDivider type="vertical" />
      <Copy text={recurrents_total}>
        <Statistic
          title=" Реккурента(ов)"
          value={recurrents_total}
          loading={loading}
        />
      </Copy>
    </TotalsContainer>
);

class Totals extends React.Component<TotalsProps, TotalsState> {
  constructor(props: TotalsProps) {
    super(props);
    this.state = {
      query: '',
      totals: [],
    };
  }

  componentDidMount() {
    const { totals, search, dispatch } = this.props;
    this.setState(() => ({
      totals,
      query: search?.query || '',
    }));
    dispatch(fetchTotals());
  }

  UNSAFE_componentWillReceiveProps(nextProps: TotalsProps) {
    const { totals, search } = nextProps;
    this.setState({
      totals,
      query: search?.query || '',
    });
  }

  handleChange = (e: InputEvent) => {
    e.persist();
    const { value } = e.target;
    if (value === '') {
      this.handleClearInput();
    }
    this.setState(() => ({
      query: value,
    }));
  };

  handleSearch = (e: InputEvent) => {
    const {
      publicKey,
      history: { push },
    } = this.props;
    e.preventDefault();
    const { query } = this.state;
    switch (true) {
      case isEncrypted(query):
        push(toSearch(query), 'email');
        break;
      case isEmail(query):
        push(toSearch(encrypt(query, publicKey.data), 'email'));
        break;
      case isLast4(query):
        push(toSearch(query, 'last4'));
        break;
      default:
        push(toSearch(query, 'customer'));
        break;
    }
  };

  handleClearInput = () => {
    const {
      dispatch,
      history: { push },
    } = this.props;
    this.setState(
      () => ({
        query: '',
      }),
      () => {
        dispatch(clearSearch());
        dispatch(fetchOrders());
        if (window.location.pathname.indexOf('/search') >= 0) {
          push('/');
        }
      },
    );
  };

  chooseLayout = () => {
    const {
      errors,
      recurrents,
      plans,
      plannedTotals: { planned, actual, failed } = {},
    } = this.props;
    const { totals } = this.state;

    const {
      loading,
      recurrents_total,
      recurrents_total_sum,
      // recurrents_rub_total_sum,
      // recurrents_usd_total_sum,
      ordersEurTotal,
      ordersRubTotal,
      ordersUsdTotal,
      // donors,
      donorsUsd,
      donorsRub,
      donorsEur,
      errors_total_rub,
      recurrentsTotalUsd,
      recurrentsTotalRub,
      recurrentsTotalEur,
      recurrentsCountRub,
      recurrentsCountUsd,
      recurrentsCountEur,
    } = totals;

    const recurrentsTotalSum = getCeilSum(recurrents_total_sum);
    const ordersTotalRub = getCeilSum(ordersRubTotal);
    const ordersTotalUsd = getCeilSum(ordersUsdTotal);
    const ordersTotalEur = getCeilSum(ordersEurTotal);
    const errorTotalRub = getCeilSum(errors_total_rub);
    const plannedTotal = getCeilSum(planned);
    const actualTotal = getCeilSum(actual);
    const failedTotal = getCeilSum(failed);
    const plannedLoading = !planned && !actual && !failed;
    if (plans) {
      return (
        <TotalsContainer>
          <Copy text={plannedTotal}>
            <Statistic
              title="Запланировано"
              value={plannedTotal}
              loading={plannedLoading}
              suffix="₽"
            />
          </Copy>
          <StyledDivider type="vertical" />
          <Copy text={actualTotal}>
            <Statistic
              title="Собрано к текущему моменту"
              value={actualTotal}
              loading={plannedLoading}
              valueStyle={{ color: '#00c656' }}
              suffix="₽"
            />
          </Copy>
          <StyledDivider type="vertical" />
          <Copy text={failedTotal}>
            <Statistic
              title="Не прошло"
              value={failedTotal}
              valueStyle={{ color: '#f1485d' }}
              loading={plannedLoading}
              suffix="₽"
            />
          </Copy>
        </TotalsContainer>
      );
    }
    if (recurrents) {
      return (
        <TotalsContainer>
          <Copy text={recurrents_total}>
            <Statistic
              title="Активных подписок"
              value={recurrents_total}
              loading={loading}
            />
          </Copy>
          <StyledDivider type="vertical" />
          <Copy text={recurrentsTotalSum}>
            <Statistic
              title="Сумма с подписок"
              value={recurrentsTotalSum}
              loading={loading}
              suffix="₽"
            />
          </Copy>
        </TotalsContainer>
      );
    }
    if (errors) {
      return (
        <TotalsContainer>
          <Copy text={errorTotalRub}>
            <Statistic
              title="Всего проебано"
              loading={loading}
              value={errorTotalRub || 0}
              suffix="₽"
            />
          </Copy>
        </TotalsContainer>
      );
    }
    return (
      <CountersContainer>
        <Counters
          ordersTotal={ordersTotalRub}
          loading={loading}
          recurrentsTotalSum={recurrentsTotalRub}
          donors={donorsRub}
          recurrents_total={recurrentsCountRub || 0}
          suffix="₽"
        />
        <Counters
          ordersTotal={ordersTotalUsd}
          loading={loading}
          recurrentsTotalSum={recurrentsTotalUsd || 0}
          donors={donorsUsd}
          recurrents_total={recurrentsCountUsd}
          suffix="$"
        />
        <Counters
          ordersTotal={ordersTotalEur}
          loading={loading}
          recurrentsTotalSum={recurrentsTotalEur || 0}
          donors={donorsEur}
          recurrents_total={recurrentsCountEur}
          suffix="€"
        />
      </CountersContainer>
    );
  };

  chooseSearch = () => {
    const {
      plans, nextDay, previousDay,
    } = this.props;
    const { query } = this.state;
    if (plans > 1) {
      return (
        <TotalsContainer>
          <DatesContainer>
            <ArrowLeftOutlined style={{ marginRight: '10px' }} />
            <DatesButton onClick={previousDay}>Предыдущий день</DatesButton>
            <StyledDivider type="vertical" />
            <DatesButton onClick={nextDay}>Следующий день</DatesButton>
            <ArrowRightOutlined style={{ marginLeft: '10px' }} />
          </DatesContainer>
        </TotalsContainer>
      );
    } if (!plans) {
      return (
        <Form>
          <Search
            size="large"
            allowClear
            placeholder="Электронная почта | Stripe ID | Last 4"
            prefix={<SearchOutlined style={{ fontSize: '22px' }} />}
            value={query}
            onChange={this.handleChange}
            onPressEnter={this.handleSearch}
          />
        </Form>
    );
    }
    return null;
  };

  render() {
    return (
      <TotalsWrapper>
        {this.chooseLayout()}
        {this.chooseSearch()}
      </TotalsWrapper>
    );
  }
}

const connectedTotals = connect((state: FullStore): {
  totals: Totals,
  search: SearchType,
  privateKey: string,
  publicKey: string,
} => ({
  totals: state.totals,
  search: state.search,
  privateKey: state.privateKey.value,
  publicKey: state.publicKey,
}))(Totals);

export default withRouter(connectedTotals);
