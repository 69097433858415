/* @flow */
import { AlertIcon } from 'Icons';
import { fetchFuturePayments } from 'actions';
import Calendar from 'components/Calendar';
import { serviceIconsMap } from 'components/Orders';
import Totals from 'components/Totals';
import { Main } from 'containers/MainContent';
import { DonorItem, StyledTable, Title } from 'containers/styled';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { PaymentMethod, decrypt } from 'utils';
import { toSearch } from 'utils/link';
import {
  getPlannedTotals,
  isPaymentFailure,
  isPaymentRefunded,
  isPaymentSuccess,
} from 'utils/planned';
import {
  AlertIconContainer,
  FailedContainer,
  FailedSum,
  RefundedSum,
  SuccessSum,
  Tooltip,
} from './styled';

const getColumns = (key: string, today: string, history) => [
  {
    title: '',
    width: 40,
  },
  {
    title: () => <b>Дата списания</b>,
    dataIndex: 'order.createdAt',
    key: 'order.createdAt',
    render: (date) => format(date, 'DD MMM YYYY, H:mm', { locale: ru }),
    width: 150,
  },
  {
    title: () => <b>Сумма</b>,
    dataIndex: 'gross_sum',
    key: 'gross_sum',
    render: (sum, record) => {
      const Today = moment(today, 'DD.MM.YYYY');
      if (isPaymentSuccess(record, Today)) {
        return <SuccessSum>{`${sum} ₽`}</SuccessSum>;
      }
      if (isPaymentFailure(record, moment(Today, 'DD.MM.YYYY'))) {
        return (
          <Tooltip placement="topLeft" title={record.order?.message || 'Error'} key={record.id}>
            <FailedContainer>
              <AlertIconContainer>
                <AlertIcon />
              </AlertIconContainer>
              <FailedSum>{`${sum} ₽`}</FailedSum>
            </FailedContainer>
          </Tooltip>
        );
      }
      if (isPaymentRefunded(record, Today)) {
        return (
          <Tooltip placement="topLeft" title="Refunded">
            <RefundedSum>{`${sum} ₽`}</RefundedSum>
          </Tooltip>
        );
      }
      return <b>{`${sum} ₽`}</b>;
    },
    width: 100,
  },
  {
    title: () => <b>Телефон</b>,
    dataIndex: 'phone',
    key: 'phone',
    width: '20%',
    ellipsis: {
      showTitle: false,
    },
    render: (phone) => (
      <Tooltip placement="topLeft" title={decrypt(phone, key)}>
        <DonorItem>{decrypt(phone, key)}</DonorItem>
      </Tooltip>
    ),
  },
  {
    title: () => <b>Электронная почта</b>,
    dataIndex: 'email',
    key: 'email',
    width: '20%',
    ellipsis: {
      showTitle: false,
    },
    render: (email, recurrent) => (
      <Tooltip placement="topLeft" title={decrypt(email, key)}>
        <DonorItem
          isLink
          onClick={() => history.push(toSearch(recurrent.email))}
        >
          {decrypt(email, key)}
        </DonorItem>
      </Tooltip>
    ),
  },
  {
    title: () => <b>Вид донора</b>,
    dataIndex: 'service',
    key: 'service',
    render: (service) => {
      const ServiceIconComponent = serviceIconsMap[service];
      return <ServiceIconComponent /> || PaymentMethod[service];
    },
    width: 100,
    align: 'center',
  },
  {
    title: () => <b>Следующее списание</b>,
    dataIndex: 'next_payment',
    key: 'next_payment',
    render: (date) => format(date, 'DD MMM YYYY, H:mm', { locale: ru }),
    width: 150,
  },
  {
    title: '',
    width: 40,
  },
];

const sortByDay = (day1, day2) => {
  if (moment(day1, 'DD.MM.YYYY').isAfter(moment(day2, 'DD.MM.YYYY'))) {
    return 1;
  }
  if (moment(day1, 'DD.MM.YYYY').isBefore(moment(day2, 'DD.MM.YYYY'))) {
    return -1;
  }
  return 0;
};

type Props = {
  dispatch(Object): void,
  payments: {
    [key: string]: Recurrent[],
  },
  history: {
    push: (string) => void,
  },
  privateKey: string,
  loading: boolean,
};

type State = {
  dateRange: {
    startDate: string | null,
    endDate: string | null,
  },
  isDateSort: boolean,
  currentDay: string | null,
  dayIndex: number,
  days: Array<string>,
  plannedTotals: {
    planned?: number,
    actual?: number,
    failed?: number,
  },
};

class PlanPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dateRange: {
        startDate: null,
        endDate: null,
      },
      isDateSort: false,
      currentDay: null,
      dayIndex: 0,
      days: [],
      plannedTotals: {},
    };
  }

  static getDerivedStateFromProps(nextProps, oldState) {
    const { payments } = nextProps;
    if (!oldState.days.length || !_.isEqual(payments, oldState.payments)) {
      const days = Object.keys(payments).sort(sortByDay);
      const plannedTotals = getPlannedTotals(payments);
      return {
        days,
        currentDay: days[0],
        plannedTotals,
        payments,
      };
    }
    return null;
  }

  componentDidMount = () => {
    this.getPayments();
  };

  getPayments = () => {
    const { dispatch } = this.props;
    const {
      dateRange: { startDate, endDate },
    } = this.state;
    dispatch(
      fetchFuturePayments({
        startDate: startDate?.toDate(),
        endDate: endDate?.toDate(),
      }),
    );
  };

  handleChangeDate = (data: Data) => {
    const { dateRange, isDateSort } = data;
    this.setState(
      () => ({
        dateRange,
        isDateSort,
      }),
      () => this.getPayments(),
    );
  };

  getTitle = () => {
    const {
      dateRange: { startDate, endDate },
    } = this.state;
    if (startDate && endDate) {
      return `на период ${format(startDate, 'DD MMMM', {
        locale: ru,
      })} - ${format(endDate, 'DD MMMM YYYY', { locale: ru })}`;
    }
    return `на ${format(new Date(), 'DD MMMM YYYY', { locale: ru })}`;
  };

  setNextDay = () => {
    const { dayIndex, days } = this.state;
    if (days.length > dayIndex + 1) {
      const nextIndex = dayIndex + 1;
      const day = days[nextIndex];
      this.setState({ dayIndex: nextIndex, currentDay: day });
    }
  };

  setPreviousDay = () => {
    const { dayIndex, days } = this.state;
    if (dayIndex >= 1) {
      const nextIndex = dayIndex - 1;
      const day = days[nextIndex];
      this.setState({ dayIndex: nextIndex, currentDay: day });
    }
  };

  render() {
    const { isDateSort, currentDay, plannedTotals } = this.state;
    const {
      payments, privateKey, loading, history,
    } = this.props;
    const data = currentDay
      ? payments[currentDay]
      : payments[Object.keys(payments)[0]];
    const columns = getColumns(privateKey, currentDay, history);
    const planDays = Object.keys(payments).length;
    return (
      <Main>
        <Title>
          План
          {' '}
          {this.getTitle()}
        </Title>
        <Totals
          plannedTotals={plannedTotals}
          nextDay={this.setNextDay}
          previousDay={this.setPreviousDay}
          plans={planDays}
        />
        <Calendar isDateSort={isDateSort} changeDate={this.handleChangeDate} />
        <StyledTable
          pagination={{ defaultPageSize: 100 }}
          size="middle"
          dataSource={data}
          columns={columns}
          loading={loading}
        />
      </Main>
    );
  }
}

export default withRouter(
  connect((state) => ({
    payments: state.payments?.list,
    loading: state.payments.loading,
    privateKey: state.privateKey?.value,
  }))(PlanPage),
);
