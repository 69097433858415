/* eslint-disable no-underscore-dangle, no-console */
/* @flow */
import * as Sentry from '@sentry/react';
import { Get, Post } from 'utils/requester';

import {
  userLoginFailure,
  userLoginOtp,
  userLoginSuccess,
  userLogout,
  userSessionCheck,
  userSessionExpired,
} from 'actions';
import type { User } from 'types';
import store from './store';

const Auth = {
  isAuthenticated(): boolean {
    const state = store.getState();
    return state?.user?.username;
  },
  async checkSession() {
    store.dispatch(userSessionCheck());
    try {
      const res: { data: User } = await Get('/admin/me');
      if (res.data && res.data.username) {
        store.dispatch(userLoginSuccess(res.data));
      }
    } catch (e) {
      Sentry.captureException(e);
      if (e.response?.status === 401) {
        store.dispatch(userSessionExpired());
      } else console.error(e);
    }
  },
  async authenticate(user: {
    password: string,
    login: string,
    'g-recaptcha-response': string,
  }) {
    try {
      const res: { data: User } = await Post('/auth/login', {
        ...user,
      });
      if (res.data.otpAuth) {
        store.dispatch(userLoginOtp(res.data));
      } else {
        store.dispatch(userLoginSuccess(res.data));
      }
    } catch (e) {
      Sentry.captureException(e);
      store.dispatch(userLoginFailure());
    }
  },
  async authenticateOtp(user: {
    password: string,
    login: string,
    code: number,
  }) {
    try {
      const res: { data: User } = await Post('/auth/login/otp', {
        ...user,
      });
      store.dispatch(userLoginSuccess(res.data));
    } catch (e) {
      Sentry.captureException(e);
      store.dispatch(userLoginFailure());
    }
  },
  async logout() {
    try {
      await Get('/auth/logout');
      store.dispatch(userLogout());
    } catch (e) {
      Sentry.captureException(e);
      console.error('logout error', e);
    }
  },
  allowFor(...allowedRoles) {
    const state = store.getState();
    const userRoles = state.user?.role?.permissions;
    return userRoles?.some((role) => allowedRoles.includes(role));
  },
};
Auth.checkSession();

export default Auth;
