/* eslint-disable react/jsx-filename-extension, camelcase */
import { Main } from 'containers/MainContent';
import numeral from 'numeral';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Get } from 'utils/requester';
import { Selector, StyledButton, StyledTable } from './styled';

const labelType = [
  {
    label: 'Всего',
    type: 'default',
  },
  {
    label: 'Месяц',
    type: 'month',
  },
  {
    label: 'Сегодня',
    type: 'day',
  },
];

const Columns = [
  {
    title: 'Всего',
    dataIndex: 'total',
    key: 'total',
    render: (value) => `${numeral(value).format('0,0')} ₽`,
  },
  {
    title: 'С подписок',
    dataIndex: 'recur',
    key: 'recur',
    render: (value) => `${numeral(value).format('0,0')} ₽`,
  },
  {
    title: 'С разовых',
    dataIndex: 'once',
    key: 'once',
    render: (value) => `${numeral(value).format('0,0')} ₽`,
  },
  {
    title: 'Всего платежей',
    dataIndex: 'totalPaymentsCount',
    key: 'totalPaymentsCount',
  },
  {
    title: 'Рекуррентных платежей',
    dataIndex: 'recurPaymentsCount',
    key: 'recurPaymentsCount',
  },
  {
    title: 'Единоразовых платежей',
    dataIndex: 'oncePaymentsCount',
    key: 'oncePaymentsCount',
  },
  {
    title: 'Новых подписчиков',
    dataIndex: 'recurCount',
    key: 'recurCount',
  },
  {
    title: 'Stripe',
    dataIndex: 'cardSum',
    key: 'cardSum',
    render: (value) => `${numeral(value).format('0,0')} ₽`,
  },
  // {
  //   title: 'Paypal',
  //   dataIndex: 'paypalSum',
  //   key: 'paypalSum',
  //   render: (value) => `${value} ₽`,
  // },
  {
    title: 'Яндекс Деньги',
    dataIndex: 'yandexSum',
    key: 'yandexSum',
    render: (value) => `${numeral(value).format('0,0')} ₽`,
  },
];

type Props = {
  dispatch(Object): void,
};

type State = {
  state: 'loading' | 'loaded',
  type: string,
  total: number,
  recur: number,
  once: number,
  totalPaymentsCount: number,
  recurPaymentsCount: number,
  oncePaymentsCount: number,
  recurCount: number,
  cardSum: number,
  paypalSum: number,
  yandexSum: number,
};

class Stats extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      type: 'default',
      state: 'loading',
    };
  }

  UNSAFE_componentWillMount() {
    const { type } = this.state;
    this.handleLoad(type);
  }

  async handleLoad(type) {
    this.setState(() => ({
      state: 'loading',
      type,
    }));
    const res = await Get(`/admin/stats?type=${type}`);
    if (res.data) {
      this.setState(() => ({ ...res.data, state: 'loaded' }));
    }
  }

  render() {
    const { state, type: stateType } = this.state;
    const data = [this.state];
    return (
      <Main>
        <Selector>
          {labelType.map(({ label, type }) => {
            if (type === stateType) {
              return <StyledButton type="primary">{label}</StyledButton>;
            }
            return (
              <StyledButton onClick={() => this.handleLoad(type)}>
                {label}
              </StyledButton>
            );
          })}
        </Selector>
        <StyledTable
          loading={state === 'loading'}
          dataSource={data}
          columns={Columns}
          pagination={false}
        />
      </Main>
    );
  }
}

export default connect()(Stats);
