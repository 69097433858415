import styled from 'styled-components';

export const Wrapper = styled.div`
  padding-bottom: 30px;
`;

export const ContentContainer = styled.div`
  border-top: 1px solid #e5e5e5;
  padding-top: 30px;
  padding-bottom: 30px;
  min-height: 100%;
`;

export const OuterWrapper = styled.div`
  background-color: #f7f7f7;
  height: 100%;
`;

export const OuterContainer = styled.div`
  background-color: #fff;
  margin: 0 150px;
  padding: 55px 80px 0;
  box-sizing: border-box;
  min-height: calc(100% - 66px);
`;

export const AddEmail = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 100%;
  border: 0;
  min-width: 30px;
  height: 30px;
  background: ${({ isPending }: { isPending: boolean }) => isPending ? '#4981ff' : '#00c656'};

  outline: 0;
  cursor: pointer;
  opacity: ${({ isFocus }: { isFocus: boolean }) => (isFocus ? 1 : 0.65)};
  transition: opacity linear 0.2s, color linear 0.2s;

  svg {
    width: 20px;
    height: 20px;
  }
  path {
    fill: #fff;
  }
`;

export const ErrorMsg = styled.div`
  color: red;
  background: #f7d4da;
  padding: 10px 5px;
  margin-top: 25px;
  font-size: 12px;
`;
export const InputLine = styled.form`
  display: flex;
`;

export const Input = styled.input`
  width: 100%;
  font-size: 14px;
  line-height: 22px;
  box-sizing: border-box;
  margin-left: 15px;

  border: 0;
  outline: 0;
  font-family: 'Graphik LC', sans-serif;
`;
