/* @flow */
/* eslint-disable camelcase */
import { fetchOrders } from 'actions';
import Calendar from 'components/Calendar';
import Orders from 'components/Orders';
import Totals from 'components/Totals';
import { format } from 'date-fns';
import locale from 'date-fns/locale/ru';
import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Container, Title, Wrapper } from './styled';

const StyledTitle = styled(Title)`
  color: #00c656;
`;

class MainContent extends React.Component<MainContentProps, $Exact<MainContentState>> {
  constructor(props: MainContentProps) {
    super(props);
    this.state = {
      orders: [],
      offset: 0,
      limit: 50,
      dateRange: {
        startDate: 'null',
        endDate: 'null',
      },
      isDateSort: false,
    };
  }

  componentDidMount() {
    const { dateRange, offset, limit } = this.state;
    const { dispatch } = this.props;
    dispatch(fetchOrders({ dateRange, offset, limit }));
  }

  UNSAFE_componentWillReceiveProps(nextProps: MainContentProps) {
    this.setState(() => ({
      orders: nextProps.orders,
    }));
  }

  handleChangeDate = (data: Data) => {
    const { dateRange, isDateSort } = data;
    const { dispatch } = this.props;
    this.setState(
      () => ({
        dateRange,
        isDateSort,
      }),
      () => dispatch(fetchOrders({ dateRange, offset: 0, limit: 30 })),
    );
  }

  handlePageClick = (data: Data) => {
    const { selected } = data;
    const { limit, dateRange } = this.state;
    const { dispatch } = this.props;
    const offset = Math.ceil(selected * limit);
    this.setState(
      () => ({
        offset,
      }),
      () => dispatch(fetchOrders({ dateRange, limit, offset })),
    );
  }

  render() {
    const {
      isDateSort, dateRange, limit, offset, orders,
    } = this.state;
    return (
      <Wrapper>
        <Container>
          <Calendar
            isDateSort={isDateSort}
            changeDate={this.handleChangeDate}
          />
          {isDateSort ? (
            <StyledTitle>
              {`За период:   ${format(new Date(dateRange.startDate), 'DD MMMM YYYY', {
                locale,
              })} —  ${format(dateRange.endDate, 'DD MMMM YYYY', { locale })}`}
            </StyledTitle>
          ) : (
            <Title>Статистика пожертвований</Title>
          )}
          <Totals />
          <Orders limit={limit} dateRange={dateRange} offset={offset} orders={orders} />
        </Container>
      </Wrapper>
    );
  }
}

export default connect((state: FullStore): {
  orders: Order[],
  pageCount: number,
  privateKey: string,
} => ({
  orders: state.orders.list,
  pageCount: state.orders.pageCount,
  privateKey: state.privateKey.value,
}))(MainContent);
