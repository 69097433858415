/* eslint-disable no-debugger,
  import/prefer-default-export,
  no-underscore-dangle,
  no-console,
  prefer-destructuring,
  consistent-return,
  camelcase
*/
import * as Sentry from '@sentry/react';
import lodashGet from 'lodash/get';
import lodashHas from 'lodash/has';

const cardDataSelectorOrder = (order) => {
  let cardData = {};
  if (lodashHas(order, 'data.source.card')) {
    cardData = order.data.source.card;
  } else if (lodashHas(order, 'data.source.last4')) {
    cardData = order.data.source;
  } else if (lodashHas(order, 'data.chargeResponse.source.last4')) {
    cardData = order.data.chargeResponse.source;
  } else if (lodashHas(order, 'data.chargeResponse.source.card')) {
    cardData = order.data.chargeResponse.source.card;
  } else if (lodashHas(order, 'data.CardLastFour')) {
    const [exp_month, exp_year] = order.data.CardExpDate.split('/');
    cardData = {
      last4: order.data.CardLastFour,
      exp_month,
      exp_year,
    };
  } else if (lodashHas(order, 'data.Model.CardLastFour')) {
    const [exp_month, exp_year] = (order.data?.Model?.CardExpDate || '').split('/');
    cardData = {
      last4: order.data?.Model?.CardLastFour,
      exp_month,
      exp_year,
    };
  }

  if (!cardData.last4 && !lodashHas(order, 'data.CardLastFour') && lodashHas(order, 'data.Model.CardLastFour')) {
    console.log('card data is not exists in order', order);
  }

  return cardData;
};

const cardDataSelectorRecurrent = (recurrent) => {
  let cardData = {};

  if (lodashGet(recurrent, 'data.cardData', false)) {
    cardData = recurrent.data?.cardData;
  } else if (lodashHas(recurrent, 'data.customer.sources.data[0].last4')) {
    cardData = recurrent.data?.customer?.sources?.data[0];
  } else if (lodashGet(recurrent, 'data.CardLastFour', false)) {
    const [exp_month, exp_year] = recurrent.data.CardExpDate.split('/');
    cardData = {
      last4: recurrent.data.CardLastFour,
      exp_month,
      exp_year,
    };
  } else {
  const [exp_month, exp_year] = (recurrent.data?.Model?.CardExpDate || '').split('/');
  cardData = {
    last4: recurrent.data?.Model?.CardLastFour,
    exp_month,
    exp_year,
  };
    }
  return cardData;
};

const selectorTypeMap = {
  order: cardDataSelectorOrder,
  recurrent: cardDataSelectorRecurrent,
};

export const cardDataSelector = (order, entityType) => {
  if (typeof selectorTypeMap[entityType] === 'undefined') {
    throw new Error(
      `cardDataSelector bad second argument(entity type). Allow only ${Object.keys(
        selectorTypeMap,
      ).join(', ')}`,
    );
  }
  try {
    const { last4, exp_month, exp_year } = selectorTypeMap[entityType](order);

    if (last4 && exp_month && exp_year) {
      return {
        cardNumber: `*${last4}`,
        expDate: `${`0${exp_month}`.slice(-2)}/${exp_year
          .toString()
          .slice(-2)}`,
      };
    }
  } catch (e) {
    Sentry.captureException(e);
    console.log('order ', order);
    console.log('entityType', entityType, e);
  }
};

export const orderRefundStatus = (order) => {
  if (order.service !== 'stripe') return 'not';
  if (order.clientMetaRefunedFailed) return 'failed';
  if (order.clientMetaProcessing) return 'processing';
  if (order.status === 'refunded') return 'refunded';
  if (order.status === 'success') return 'refundable';

  return 'not';
};

export const getAuthToken = (store) => {
  const storeToken = store.user && store.user.token;
  const savedToken = localStorage.getItem('token');
  return storeToken || savedToken;
};

export const mapOrderToRecurrent = (recurrent: Recurrent) => {
  const fields = Object.keys(recurrent);
  const mappedRecurrent = { order: {} };
  fields.forEach((item) => {
    if (item.includes('order')) {
      const orderField = item.split('.')[1];
      mappedRecurrent.order[orderField] = recurrent[item];
    }
    mappedRecurrent[item] = recurrent[item];
  });
  return mappedRecurrent;
};

export const sortPlannedByOrdersDate = (a, b) => {
  const now = moment();
  const aDate = moment(a.order?.createdAt);
  const bDate = moment(b.order?.createdAt);
  if (aDate.isValid() && bDate.isValid()) {
    const aTime = now
      .clone()
      .set('hour', aDate.hour())
      .set('minutes', aDate.minutes())
      .set('milliseconds', aDate.milliseconds());
    const bTime = now
      .clone()
      .set('hour', bDate.hour())
      .set('minutes', bDate.minutes())
      .set('milliseconds', bDate.milliseconds());
    if (aTime.valueOf() > bTime.valueOf()) {
      return 1;
    }
    if (aTime.valueOf() < bTime.valueOf()) {
      return -1;
    }
    return 0;
  }
};

export const proceedPlanned = (planned) => {
  const days = Object.keys(planned);
  const precededPlanned = {};
  days.forEach((day) => {
    precededPlanned[day] = planned[day]
      ?.map(mapOrderToRecurrent)
      .sort(sortPlannedByOrdersDate);
  }, {});
  return precededPlanned;
};
