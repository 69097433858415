export const SUCCESS = '_SUCCESS';
export const FAIL = '_FAIL';

export const FETCH_ORDERS = 'FETCH_ORDERS';
export const RECEIVE_ORDERS = 'RECEIVE_ORDERS';
export const CREATE_ORDER = 'CREATE_ORDER';
export const FETCH_RECURRENTS = 'FETCH_RECURRENTS';
export const RECEIVE_RECURRENTS = 'RECEIVE_RECURRENTS';
export const FETCH_SEARCH = 'FETCH_SEARCH';
export const RECEIVE_SEARCH = 'RECEIVE_SEARCH';
export const LOAD_SEARCH_REQUEST = 'LOAD_SEARCH_REQUEST';
export const LOAD_SEARCH_END = 'LOAD_SEARCH_END';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';
export const FETCH_TOTALS = 'FETCH_TOTALS';
export const RECEIVE_TOTALS = 'RECEIVE_TOTALS';
export const PRIVATE_KEY_SET = 'PRIVATE_KEY_SET';
export const FETCH_CUSTOM_ORDER = 'FETCH_CUSTOM_ORDER';
export const RECEIVE_CUSTOM_ORDER = 'RECEIVE_CUSTOM_ORDER';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const CHANGE_PAYMENT_DATE = 'CHANGE_PAYMENT_DATE';
export const CHANGE_PAYMENT_SUM = 'CHANGE_PAYMENT_SUM';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_OTP = 'USER_LOGIN_OTP';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const USER_SESSION_CHECK = 'USER_SESSION_CHECK';
export const USER_SESSION_EXPIRED = 'USER_SESSION_CHECK';
export const SET_PUBLIC_KEY = 'SET_PUBLIC_KEY';
export const FETCH_ERRORS = 'FETCH_ERRORS';
export const RECEIVE_ERRORS = 'RECEIVE_ERRORS';
export const FETCH_HOLD_EMAILS = 'FETCH_HOLD_EMAILS';
export const RECEIVE_HOLD_EMAILS = 'RECEIVE_HOLD_EMAILS';
export const ADD_HOLD_EMAILS = 'ADD_HOLD_EMAILS';
export const FAIL_ADD_HOLD_EMAILS = 'FAIL_ADD_HOLD_EMAILS';
export const SUCCESS_ADD_HOLD_EMAIL = 'SUCCESS_ADD_HOLD_EMAIL';
export const SUCCESS_ADD_HOLD_EMAILS = 'SUCCESS_ADD_HOLD_EMAILS';
export const REMOVE_HOLD_EMAIL = 'REMOVE_HOLD_EMAIL';
// export const CHANGE_HOLD_EMAIL_CONDITIONS = 'CHANGE_HOLD_EMAIL_CONDITIONS';

export const TRY_REFUND_ORDER = 'TRY_REFUND_ORDER';
export const SUCCESS_REFUND_ORDER = 'SUCCESS_REFUND_ORDER';
export const FAIL_REFUND_ORDER = 'FAIL_REFUND_ORDER';

export const GET_SUBSCRIBERS = 'GET_SUBSCRIBERS';
export const RECEIVE_SUBSCRIBERS = 'RECEIVE_SUBSCRIBERS';
export const CREATE_MAIL_CAMPAIGN = 'CREATE_MAIL_CAMPAIGN';
export const GET_UNSUBSCRIBERS = 'GET_UNSUBSCRIBERS';
export const RECEIVE_UNSUBSCRIBERS = 'RECEIVE_UNSUBSCRIBERS';
export const GET_CAMPAIGNS = 'GET_CAMPAIGNS';
export const RECEIVE_CAMPAIGNS = 'RECEIVE_CAMPAIGNS';
export const ATTACH_OTP = 'ATTACH_OTP';
export const DETACH_OTP = 'DETACH_OTP';
export const ENABLE_OTP = 'ENABLE_OTP';
export const DISABLE_OTP = 'DISABLE_OTP';
export const FETCH_FUTURE_PAYMENTS = 'FETCH_FUTURE_PAYMENTS';
export const RECEIVE_FUTURE_PAYMENTS = 'RECEIVE_FUTURE_PAYMENTS';
export const RECEIVE_FUTURE_PAYMENTS_FAIL = `${RECEIVE_FUTURE_PAYMENTS}${FAIL}`;

export const DISABLE_CLOUDPAYMENTS = 'DISABLE_CLOUDPAYMENTS';
