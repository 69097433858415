/* @flow */
export const normalizeDate = (value: string): string => {
  if (!value) {
    return value;
  }
  const onlyNums: string = value.replace(/[^\d]/g, '');

  switch (true) {
    case onlyNums.length <= 2:
      return onlyNums;
    case onlyNums.length > 2 && onlyNums.length <= 4:
      return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 4)}`;
    case onlyNums.length > 4 && onlyNums.length <= 8:
      return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 4)}.${onlyNums.slice(4, 8)}`;
    case onlyNums.length > 8 && onlyNums.length <= 10:
      return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 4)}.${onlyNums.slice(
        4,
        8,
      )} ${onlyNums.slice(8, 10)}`;
    case onlyNums.length > 10 && onlyNums.length <= 12:
      return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 4)}.${onlyNums.slice(
        4,
        8,
      )} ${onlyNums.slice(8, 10)}:${onlyNums.slice(10, 12)}`;
    default:
      return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 4)}.${onlyNums.slice(
        4,
        8,
      )} ${onlyNums.slice(8, 10)}:${onlyNums.slice(10, 12)}`;
  }
};

export const formatDate = (date: string): string => {
  const onlyNums: string = date.replace(/[^\d]/g, '');
  switch (true) {
    case onlyNums.length === 8:
      return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 6)}-${onlyNums.slice(6, 8)} 00:00`;
    case onlyNums.length === 9:
      return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 6)}-${onlyNums.slice(
        6,
        8,
      )} 0${onlyNums.slice(8, 9)}:00`;
    case onlyNums.length === 10:
      return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 6)}-${onlyNums.slice(
        6,
        8,
      )} ${onlyNums.slice(8, 10)}:00`;
    case onlyNums.length === 11:
      return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 6)}-${onlyNums.slice(
        6,
        8,
      )} ${onlyNums.slice(8, 10)}:0${onlyNums.slice(10, 11)}`;
    default:
      return date;
  }
};
