import {
  BitcoinIcon,
  CloudpaymentsIcon,
  PaypalIcon,
  SbrfIcon,
  StripeIcon,
  YandexMoneyIcon,
} from 'Icons';

export const ROLES = { superadmin: 'superadmin', guest: 'guest', newsletter: 'newsletter' };

export const errorsMap = {
  cancelled: { text: 'Отмена', color: '#f17f48' },
  not_start: { text: 'Попытка', color: 'red' },
  active: { text: 'Активная', color: '#00c656' },
  ended: { text: 'Завершилась', color: '#f1485d' },
  not_paid: { text: 'Неуспешная', color: 'red' },
};

export const serviceIconsMap = {
  stripe: StripeIcon,
  paypal: PaypalIcon,
  yandex_dengi: YandexMoneyIcon,
  direct: SbrfIcon,
  bitcoin: BitcoinIcon,
  cloudpayments: CloudpaymentsIcon,
};

export const APP_VERSION = process.env.npm_package_version;
