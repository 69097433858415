/* @flow */

import { fetchErrors } from 'actions';
import Calendar from 'components/Calendar';
import Error from 'components/Errors';
import Totals from 'components/Totals';
import { format } from 'date-fns';
import locale from 'date-fns/locale/ru';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Title } from './styled';

const Wrapper = styled.div`
  background-color: #f7f7f7;
`;

const Container = styled.div`
  background-color: #fff;
  margin: 0 150px 60px 150px;
  padding: 55px 80px 0;
  position: relative;

  @media (max-width: 1200px) {
    margin: 0 50px 60px 50px;
  }
`;

const StyledTitle = styled(Title)`
  color: #00c656;
`;

class Errors extends React.Component<ErrorsProps, ErrorsState> implements ErrorsClass {
  constructor(props: MainContentProps) {
    super(props);
    this.state = {
      errors: [],
      offset: 0,
      limit: 50,
      dateRange: {
        startDate: 'null',
        endDate: 'null',
      },
      isDateSort: false,
    };
  }

  componentDidMount() {
    const { dateRange, offset, limit } = this.state;
    const { dispatch } = this.props;
    dispatch(fetchErrors({ dateRange, offset, limit }));
  }

  static getDerivedStateFromProps(props) {
    return {
      errors: props.errors,
    };
  }

  handleChangeDate(data: PaginationDataType) {
    const { dateRange, isDateSort } = data;
    const { dispatch } = this.props;
    this.setState(
      () => ({
        dateRange,
        isDateSort,
      }),
      () => dispatch(fetchErrors({ dateRange, offset: 0, limit: 30 })),
    );
  }

  handlePageClick(data: PaginationDataType) {
    const { selected } = data;
    const { limit, dateRange } = this.state;
    const { dispatch } = this.props;
    const offset = Math.ceil(selected * limit);
    this.setState(
      () => ({
        offset,
      }),
      () => dispatch(fetchErrors({ dateRange, limit, offset })),
    );
  }

  render() {
    const {
      isDateSort, dateRange, limit, offset, errors,
    } = this.state;
    const { privateKey } = this.props;
    return (
      <Wrapper>
        <Container>
          <Calendar
            isDateSort={isDateSort}
            changeDate={(date: PaginationDataType) => this.handleChangeDate(date)}
          />
          {isDateSort ? (
            <StyledTitle>
              {`За период:   ${format(new Date(dateRange.startDate), 'DD MMMM YYYY', {
                locale,
              })} —  ${format(dateRange.endDate, 'DD MMMM YYYY', { locale })}`}
            </StyledTitle>
          ) : (
            <Title>Статистика ошибок</Title>
          )}
          <Totals errors />
          <Error
            limit={limit}
            dateRange={dateRange}
            offset={offset}
            errors={errors}
            privateKey={privateKey}
          />
        </Container>
      </Wrapper>
    );
  }
}

export default connect((state: FullStore): {
  errors: OrderType[],
  pageCount: number,
  privateKey: string,
} => ({
  errors: state.errors.list,
  pageCount: state.errors.pageCount,
  privateKey: state.privateKey.value,
}))(Errors);
