import styled from 'styled-components';

export const Container = styled.div``;

export const Wrapper = styled.div`
  align-items: center;
  background: #000;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;

  width: 40px;
  transition: all 0.2s;

  &:hover {
    transform: scale(1.2, 1.2);
  }
`;

export const GreenWrapper = styled(Wrapper)`
  background: #00c656;
  svg {
    fill: #fff;
  }
`;

export const IconContainer = styled.div`
  border-radius: 50%;
  border: 10px solid #f7f7f7;
  left: -30px;
  top: 60px;
  position: absolute;
`;

export const DateContainer = styled.div`
  position: absolute;
  top: 120px;
  left: 0px;
  z-index: 2;
`;

export const ModalWrapper = styled.div`
  bottom: 0;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
`;
