/* @flow */
import { Button, Table, Tooltip as UnstyledTooltip } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledTable = styled(Table)`
  .ant-table-thead {
    font-family: 'Fira Mono', sans-serif;
  }

  .ant-table-content tbody tr:nth-child(odd) {
    background-color: #fff;
  }

  .ant-table-content tbody tr:nth-child(even) {
    background-color: #f7f7f7;
  }

  .ant-table-content .ant-table-tbody > tr:hover > td {
    background-color: #f4f6df;
  }
`;

export const Wrapper = styled.div`
  background-color: #f7f7f7;
`;

export const Container = styled.div`
  background-color: #fff;
  margin: 0 150px 60px 150px;
  padding: 55px 80px 0;
  position: relative;

  @media (max-width: 1200px) {
    margin: 0 50px 60px 50px;
  }
`;

export const Title = styled.span`
  display: inline-block;
  font-family: 'Graphik LC', sans-serif;
  font-size: 26px;
  font-weight: bold;
  padding: 27px 0;
  text-align: left;
`;

export const DonorsTitle = styled.div`
  align-items: center;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-around;
  height: 38px;
  padding: 0 30px;
`;

export const DonorsTitleItem = styled.span`
  flex-basis: ${(props) => props.w || '15%'};
  font: bold 11px 'Fira Mono', sans-serif;
`;

export const Donor = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-around;
  height: 50px;
  padding: 0 30px;

  &:nth-child(2n) {
    background-color: #f7f7f7;
  }
  &:last-child {
    border-bottom: 1px solid #e5e5e5;
  }

  &:hover {
    background-color: #f4f6df;
  }
`;

export const DonorItem = styled.span`
  align-items: center;
  color: ${(props: { isLink: boolean }) => (props.isLink ? '#0036ff' : '#333')};
  cursor: ${(props: { isLink: boolean }) => (props.isLink ? 'pointer' : '')};
  display: inline-flex;
  flex-basis: ${(props) => props.w || '15%'};
  font: 12px 'Graphik LC', sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;

  strong {
    margin-right: 5px;
  }

  svg {
    fill: #00c656;
    margin-right: 10px;
  }
`;

export const DonorItemLink = DonorItem.withComponent(Link);

export const DonorItemLikeLink = styled(DonorItem)`
  cursor: pointer;
  color: #0036ff;
`;

export const Cry = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 80px;
`;

export const DownloadButton = styled(Button)`
  align-self: flex-start;
  background: #fff;
  border-radius: 0;
  border: solid 1px #e5e5e5;
  color: #0036ff;
  cursor: pointer;
  font-family: FiraMono;
  font-size: 12px;
  margin-left: 40px;
  padding: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
`;

export const SuccessSum = styled.b`
  color: #00c656;
`;

export const FailedContainer = styled.div`
  position: relative;
`;

export const FailedSum = styled.b`
  color: #f1485d;
`;

export const RefundedSum = styled.b`
  color: #7174e2;
`;

export const AlertIconContainer = styled.div`
  position: absolute;
  left: -20px;
  top: 2px;
`;

export const AlertAttemptCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  background-color: #f1485d;
  width: 16px;
  height: 16px;
  font-size: 11px;
`;

export const Tooltip = styled(UnstyledTooltip)`
  display: flex;
  align-items: center;
`;
