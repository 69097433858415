import { Modal } from 'antd';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  & .ant-modal-body {
    display: flex;
    flex-direction: column;
  }
`;

export const Wrapper = styled.div`
  bottom: 0;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
`;

export const Container = styled.div`
  background-color: #ffffff;
  box-shadow: 0 0 100px 0 rgba(0, 0, 0, 0.4);
  height: ${({ height }) => height || '380px'};
  width: 520px;
  z-index: 599999;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
`;

export const SubmitButton = styled.button`
  background-color: #0036ff;
  border-radius: 0;
  border: none;
  color: #fff;
  font-family: 'Fira Mono';
  font-size: 11px;
  margin-bottom: 20px;
  padding: 12px 0 10px 0;
  width: 100%;
`;

export const RSAField = styled.textarea`
  width: 100%;
  min-height: 150px;
  box-sizing: border-box;
  margin-bottom: 25px;
`;

export const Content = styled.div`
`;

export const Title = styled.h3`
  font-family: 'Graphik LC';
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 37px;
`;
