/* @flow */
/* eslint-disable camelcase */
import { Modal, message } from 'antd';
import { format } from 'date-fns';
import locale from 'date-fns/locale/ru';
import moment from 'moment';
import type Moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import styled from 'styled-components';
import { Title } from '../styled';

import { createOrder, fetchCustomOrder } from '../../../actions/index';
import { normalizeDate } from '../../../utils/date';

import type { Order } from '../../../types';

const Sum = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const SumDescription = styled.span`
  font-family: 'Fira Mono', sans-serif;
  font-size: 11px;
  line-height: 1.64;
  flex-basis: 40%;
  opacity: 0.5;
`;

const SumInput = styled.input`
  border: 1px solid #e5e5e5;
  font-family: 'Fira Mono', sans-serif;
  font-size: 14px;
  max-width: 100px;
  padding: 8px 14px;

  &::placeholder {
    opacity: 0.5;
    font-family: 'Fira Mono', sans-serif;
  }
`;

const Source = styled(Sum)``;

const SourceDescription = styled(SumDescription)``;

const StyledSelect = styled(Select)`
  flex-basis: 60%;

  &.Select--single {
    .Select-value {
      display: inline-flex;
      align-items: center;
    }
  }
  & .Select-control {
    border-color: #e5e5e5;
    border-radius: 0;
  }

  & .Select-placeholder {
    font-size: smaller;
  }
`;

const Donor = styled(Sum)``;

const DonorDescription = styled(SumDescription)``;

const DonorInput = styled(SumInput)``;

const SubmitButton = styled.button`
  background-color: #0036ff;
  border-radius: 0;
  border: none;
  color: #fff;
  font-family: 'Fira Mono', sans-serif;
  font-size: 11px;
  margin-bottom: 20px;
  padding: 12px 0 10px 0;
  width: 100%;
`;

const Time = styled(Sum)``;

const TimeDescription = styled(SumDescription)``;

const TimeInput = styled(SumInput)`
  max-width: 100%;
`;

const LastPayment = styled.div`
  font-family: 'Fira Mono', sans-serif;
  font-size: 11px;
  line-height: 1.64;
  opacity: 0.5;
`;

const SelectOptions = [
  {
    value: 'ethereum',
    label: 'Ethereum',
  },
  {
    value: 'bitcoin',
    label: 'Bitcoin',
  },
  {
    value: 'zencash',
    label: 'Zencash',
  },
  {
    value: 'direct',
    label: 'Перевод на счет',
  },
  {
    value: 'other',
    label: 'other',
  },
];

type AddOrderProps = {
  dispatch(Object): void,
  hideFn(): void,
  lastPayment: Order,
  username: string,
  visible: boolean,
};

type AddOrderState = {
  sum: string,
  source: string,
  count: string,
  lastPayment: Order | null,
  time: string | number,
};

class AddOrder extends React.Component<AddOrderProps, AddOrderState> {
  constructor(props: AddOrderProps) {
    super(props);
    this.state = {
      sum: '',
      source: '',
      count: '',
      lastPayment: null,
      time: '',
    };
    window.moment = moment;
    window.document.moment = moment;
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchCustomOrder());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { lastPayment } = nextProps;
    this.setState(() => ({
      lastPayment,
    }));
  }

  handleClick = () => {
    const { hideFn } = this.props;
    this.setState(() => ({
      sum: '',
      source: '',
      count: '',
      time: '',
    }));
    hideFn();
  }

  handleChange = (e: { target: { name: string, value: string } }) => {
    const { name, value } = e.target;
    const onlyNums = value.replace(/[^\d]/g, '');
    this.setState(() => ({
      [name]: onlyNums,
    }));
  }

  handleTimeChange = (e: { target: { value: string } }) => {
    const { value } = e.target;
    const time: string = normalizeDate(value);
    this.setState(() => ({
      time,
    }));
  }

  handleSelectChange = ({ value }: { value: string }) => {
    this.setState(() => ({
      source: value,
    }));
  }

  handleSubmit = (e: InputEvent) => {
    const {
      sum, count, source, time,
    } = this.state;
    const { dispatch, username } = this.props;
    e.preventDefault();
    if (!sum || !count || !source || !time) {
      message.error('Заполните все поля!');
      return false;
    }
    const newState: {
      sum: string,
      count: string,
      source: string,
      time: Moment,
    } = {
      sum,
      count,
      source,
      time: moment(time, 'DD/MM/YYYY HH:mm').valueOf(),
    };
    dispatch(createOrder(newState, username));
    this.handleClick();
    return false;
  }

  render() {
    const {
      sum, source, count, time, lastPayment,
    } = this.state;
    const { visible, hideFn } = this.props;

    return (
      <Modal visible={visible} onCancel={hideFn} footer={false} centered>
        <Title>Добавление платежа</Title>
        <Sum>
          <SumDescription>Сумма</SumDescription>
          <SumInput
            name="sum"
            placeholder="0"
            value={sum}
            onChange={this.handleChange}
          />
        </Sum>
        <Source>
          <SourceDescription>Источник</SourceDescription>
          <StyledSelect
            options={SelectOptions}
            name="source"
            placeholder="Выбрать источник"
            value={source}
            clearable={false}
            onChange={(val: { value: string } = { value: '' }) => this.handleSelectChange(val)}
          />
        </Source>
        <Donor>
          <DonorDescription>Количество доноров</DonorDescription>
          <DonorInput
            name="count"
            placeholder="0"
            value={count}
            onChange={this.handleChange}
          />
        </Donor>
        <Time>
          <TimeDescription>Время получения</TimeDescription>
          <TimeInput
            name="count"
            placeholder=""
            value={time}
            onChange={this.handleTimeChange}
          />
        </Time>
        <SubmitButton onClick={this.handleSubmit}>
          Добавить
        </SubmitButton>
        <LastPayment>
          <span>Предыдущее обновление:</span>
          <br />
          {lastPayment && (
            <span>
              {format(lastPayment.createdAt, 'DD MMMM', { locale })}
              ,
              {' '}
              {lastPayment.gross_sum}
              {' '}
              рублей от пользователя
              {' '}
              {lastPayment.email}
            </span>
          )}
        </LastPayment>
      </Modal>
    );
  }
}
export default connect((state: FullStore): {
  LastPayment: Order,
  username: string,
} => ({
  lastPayment: state.orders.lastPayment,
  username: state.user.username,
}))(AddOrder);
