import React from 'react';
import styled from 'styled-components';

import { toExternalServiceRecurrent } from 'utils/link';

const Subtitle = styled.div`
  font-size: 16px;
  font-family: 'Graphik LC';
  color: #333;
  margin-bottom: 14px;

  a {
    display: inline-block;
    background-color: ${(props) => props.bg};
    color: ${(props) => props.color};
    text-decoration: none;
    padding: 3px 5px;
  }
  a:visited {
    color: ${(props) => props.color};
  }
`;

const statusColorMap = {
  not_start: 'black',
  active: 'black',
  ended: 'black',
  cancelled: 'black',
};
const statusBgMap = {
  not_start: '#9CDEFF',
  active: '#F8E71C',
  ended: '#DFDFDF',
  cancelled: '#FF9C82',
};

export default ({ id, service, status }: { id: string, service: string, status: string }) => {
  const link = toExternalServiceRecurrent(id, service) || '#';
  const textColor = statusColorMap[status] || 'black';
  const bgColor = statusBgMap[status] || 'white';

  return (
    <Subtitle color={textColor} bg={bgColor}>
      ID:
      {' '}
      <a target="_blank" rel="noopener noreferrer" href={link}>
        {id}
      </a>
    </Subtitle>
  );
};
