/* @flow */
import * as Sentry from '@sentry/react';
import 'antd/dist/antd.css';
import React from 'react';
import 'react-dates/initialize';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { history } from 'store';
import styled, { withTheme } from 'styled-components';
import EmailList from './components/EmailList';
import Header from './components/Header';
import Mailings from './components/Mailings';
import Stats from './components/Stats';
import Errors from './containers/Errors';
import Login from './containers/Login';
import Orders from './containers/Orders';
import Plans from './containers/Plans';
import PrivateRoute from './containers/PrivateRoute';
import Recurrents from './containers/Reccurents';
import Search from './containers/Search';

import './App.css';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const HeaderWrapper = ({ children }: { children: React$Element<*> }) => (
  <Wrapper>
    <Header />
    {children}
  </Wrapper>
);

const App = () => (
  <ConnectedRouter history={history}>
    <HeaderWrapper>
      <Switch>
        <PrivateRoute exact path="/" component={Orders} />
        <Route path="/login" component={Login} />
        <PrivateRoute path="/recurrents" component={Recurrents} />
        <PrivateRoute path="/search" component={Search} />
        <PrivateRoute path="/errors" component={Errors} />
        <PrivateRoute path="/stats" component={Stats} />
        <PrivateRoute path="/email_list" component={EmailList} />
        <PrivateRoute path="/mailings" component={Mailings} />
        <PrivateRoute path="/plans" component={Plans} />
      </Switch>
    </HeaderWrapper>
  </ConnectedRouter>
);

export default Sentry.withProfiler(withTheme(App));
