/* @flow */
import * as Sentry from '@sentry/react';
import { SET_PUBLIC_KEY, USER_LOGIN_SUCCESS } from 'actions/constants';
import type History from 'history';
import createHistory from 'history/createBrowserHistory';
import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import reducer from '../reducers';

import type { Store } from '../types';

const browserHistory: History = createHistory();

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    if (action.type === USER_LOGIN_SUCCESS) {
      // Return null to not log the action to Sentry
      return {
        ...action,
        user: {
          ...action.user,
          qrCode: '***',
        },
      };
    }
    if (action.type === SET_PUBLIC_KEY) {
      // Return a transformed action to remove sensitive information
      return '***';
    }

    return action;
  },
  stateTransformer: (state) => {
    const transformedState = {
      ...state,
      user: {
        ...state.user,
        qrCode: '***',
      },
      privateKey: '***',
    };

    return transformedState;
  },
});

const store: Store = createStore(
  reducer,
  composeWithDevTools(
    applyMiddleware(thunk, routerMiddleware(browserHistory)),
    sentryReduxEnhancer,
  ),
);

export const history = browserHistory;

window.store = store;
export default store;
