/* @flow */
import Auth from 'Auth';
import { Crutch } from 'Icons';
import AddOrderContainer from 'components/Modals/AddOrder';
import TwoFAModal from 'components/Modals/AttachOtp';
import BigRedButtonModal from 'components/Modals/DisableCloud';
import RsaPrivateModal from 'components/Modals/RsaPrivate';
import { ROLES } from 'constants.js';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import exit from './exit.svg';
import flasher from './flasher.svg';
import key from './key1.svg';
import {
  BigRedButton,
  ButtonsContainer,
  Container,
  CrutchNavItem,
  Exit,
  ExitWrapper,
  Key,
  Logo,
  Menu,
  MenuButton,
  MenuItem,
  TwoFA,
} from './styled';

type Props = {
  username: string,
  location: {
    pathname: string,
  },
};

const Header = (props: Props) => {
  const [isTwoFAOpen, handleTwoFAModal] = useState(false);
  const [isAddOrderOpen, handleAddOrderModal] = useState(false);
  const [isRsaModalOpen, handleRsaModal] = useState(false);
  const [isBigRedButtonOpen, handleBigRedButtonModal] = useState(false);
  const { username } = props;
  if (!username) {
    return null;
  }
  const {
    location: { pathname },
  } = props;
  return (
    <Container>
      <Logo to="/">МЗ</Logo>
      <Menu>
        {Auth.allowFor(ROLES.superadmin) && (
        <MenuItem to="/" isSelected={pathname === '/'}>
          Краудфандинг
        </MenuItem>
)}
        {Auth.allowFor(ROLES.superadmin) && (
        <MenuItem to="/errors" isSelected={pathname === '/errors'}>
          Ошибки
        </MenuItem>
)}
        {Auth.allowFor(ROLES.superadmin) && (
        <MenuItem to="/recurrents" isSelected={pathname === '/recurrents'}>
          Подписки
        </MenuItem>
)}
        {Auth.allowFor(ROLES.superadmin) && (
        <MenuItem to="/stats" isSelected={pathname === '/stats'}>
          Статистика
        </MenuItem>
)}
        {Auth.allowFor(ROLES.superadmin, ROLES.newsletter) && (
          <MenuItem to="/mailings" isSelected={pathname === '/mailings'}>
            Рассылка
          </MenuItem>
        )}
        {Auth.allowFor(ROLES.superadmin) && (
          <MenuItem to="/plans" isSelected={pathname === '/plans'}>
            План
          </MenuItem>
        )}
        {Auth.allowFor(ROLES.superadmin) && (
          <MenuButton
            onClick={() => Auth.isAuthenticated() && handleAddOrderModal(!isAddOrderOpen)}
          >
            Добавить платёж
          </MenuButton>
        )}
      </Menu>
      <AddOrderContainer
        visible={isAddOrderOpen}
        hideFn={() => handleAddOrderModal(!isAddOrderOpen)}
      />
      <RsaPrivateModal
        visible={isRsaModalOpen}
        hideFn={() => handleRsaModal(!isRsaModalOpen)}
      />
      <TwoFAModal
        visible={isTwoFAOpen}
        hideFn={() => handleTwoFAModal(!isTwoFAOpen)}
      />
      <BigRedButtonModal
        visible={isBigRedButtonOpen}
        hideFn={() => handleBigRedButtonModal(!isBigRedButtonOpen)}
      />
      <ButtonsContainer>
        {Auth.allowFor(ROLES.superadmin) && (
          <BigRedButton
            src={flasher}
            onClick={() => Auth.isAuthenticated() && handleBigRedButtonModal(!isBigRedButtonOpen)}
          />
        )}
        {Auth.allowFor(ROLES.superadmin) && (
          <CrutchNavItem to="/email_list">
            <Crutch />
          </CrutchNavItem>
        )}
        {Auth.allowFor(ROLES.superadmin) && (
        <Key
          src={key}
          onClick={() => Auth.isAuthenticated() && handleRsaModal(!isRsaModalOpen)}
        />
)}
        <TwoFA type="qrcode" onClick={() => handleTwoFAModal(!isTwoFAOpen)} />
        <ExitWrapper onClick={() => Auth.logout()}>
          <img src={exit} alt="exit" />
          <Exit>Выйти</Exit>
        </ExitWrapper>
      </ButtonsContainer>
    </Container>
  );
};

export default withRouter(
  connect(({ user }) => ({ username: user.username }))(Header),
);
