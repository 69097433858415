/* @flow */
import * as Sentry from '@sentry/react';
import { JSEncrypt } from 'js-encrypt-without-padding-node';

export const currency = {
  USD: '$',
  RUB: '₽',
  EUR: '€',
};

export const PaymentMethod = {
  yandex_kassa: 'Яндекс.Касса',
  yandex_dengi: 'Яндекс.Деньги',
  paypal: 'Paypal',
  direct: 'На счет',
  stripe: 'Stripe',
  bitcoin: 'Bitcoin',
  ethereum: 'Ethereum',
  cloudpayments: 'Cloudpayments',
};

export const decrypt = (data: string, privateKey: string): string => {
  try {
    if (!privateKey) return data;
    const descriptor = new JSEncrypt();
    descriptor.setPrivateKey(privateKey);
    const decryptedData: string = descriptor.decrypt(data);
    if (decryptedData) return decryptedData.trim();
    return data;
  } catch (e) {
    Sentry.captureException(e);
    return data;
  }
};

export const encrypt = (data: string, publicKey: string): string => {
  try {
    const descriptor = new JSEncrypt();
    descriptor.setPublicKey(publicKey);
    const encryptedData: string = descriptor.encrypt(data.toLowerCase());
    if (encryptedData) return encryptedData.trim();
    return data;
  } catch (e) {
    Sentry.captureException(e);
    return data;
  }
};
