/* @flow */
/* eslint-disable import/prefer-default-export */
import { notification } from 'antd';

const config = {
  duration: 15,
};

export const sendNotification = (
  type: 'success' | 'warn' | 'warning' | 'error' | 'info',
  title: string,
  description: string,
) => {
  notification.config(config);
  notification[type]({
    message: title,
    description,
  });
};
