/* eslint-disable */
/* @flow */
import { fetchSearch } from 'actions';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Calendar from 'components/Calendar';
import Orders from 'components/Orders';
import Recurrents from 'components/Recurrents';
import Totals from 'components/Totals';
import { Title } from 'containers/styled';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: #f7f7f7;
`;

const Container = styled.div`
  background-color: #fff;
  margin: 0 150px 60px 150px;
  padding: 55px 80px 0;
  position: relative;
  padding-bottom: 30px;
`;

const Block = styled.div`
  margin-bottom: 36px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

const BlockTitle = styled.div`
  font: bold 16px 'Graphik LC', sans-serif;
  margin-bottom: 17px;
  margin-top: 24px;
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
`;

const RecurrentsWrapper = styled.div`
  margin-bottom: 20px;
`;

class Search extends React.Component<SearchProps, SearchState> {
  componentDidMount() {
    this.dispatchFetch();
  }

  componentWillReceiveProps(nextProps: SearchProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.dispatchFetch(nextProps.location.search);
    }
  }

  getParams = (search = this.props.location.search) => {
    const params = new URLSearchParams(search.substring(1));
    const q = params.get('q');
    const t = params.get('t');
    return { type: t, query: q };
  }

  dispatchFetch = () => {
    const { type, query } = this.getParams();
    this.props.dispatch(fetchSearch(query, type));
  }

  handleDateFilter(data: Data) {}

  render() {
    const {
      recurrentOrders,
      transactionalOrders,
      recurrents,
      isFetching,
    } = this.props.search;
    const { type } = this.getParams();

    return (
      <Wrapper>
        <Container>
          <Calendar changeDate={(date: Data) => this.handleDateFilter(date)} />
          <Title>Статистика пожертвований</Title>
          <Totals />
          {isFetching ? (
            <Loading>
              <Spin
                indicator={
                  <LoadingOutlined style={{ fontSize: 82 }} />
                }
              />
            </Loading>
          ) : null}

          {recurrents && recurrents.length ? (
            <Block>
              <BlockTitle>Подписки</BlockTitle>
              {recurrents.map((recurrent) => (
                <RecurrentsWrapper>
                  <Recurrents searchType={type} isSearch recurrents={[recurrent]} />
                </RecurrentsWrapper>
              ))}
            </Block>
          ) : 
            null
          }

          {recurrentOrders && recurrentOrders.length ? (
            <Block>
              <BlockTitle>Транзакции</BlockTitle>
              <Orders searchType={type} isSearch orders={recurrentOrders} />
            </Block>
          ) : 
            null
          }

          {transactionalOrders && transactionalOrders.length ? (
            <Block>
              <BlockTitle>Единоразовые</BlockTitle>
              <Orders searchType={type} isSearch orders={transactionalOrders} />
            </Block>
          ) : 
            null
          }
        </Container>
      </Wrapper>
    );
  }
}

export default connect((state: FullStore, props: SearchProps): {
  search: SearchType,
  searchQuery: string,
} => ({
  search: state.search,
  searchQuery: props.location.search,
}))(Search);
