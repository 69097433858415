import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0036ff;
  border-radius: 0;
  border: none;
  color: #fff;
  font-family: 'Fira Mono';
  font-size: 11px;
  margin-bottom: 20px;
  padding: 12px 0 10px 0;
  width: 100%;
`;

export const TwoFAContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 36px 50px;
`;

export const Close = styled(CloseOutlined)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const Img = styled.img`
  margin: auto;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InputContainer = styled.div`
  margin-bottom: 10px;
`;

export const AttachButton = styled(StyledButton)`
  margin-top: 10px;
`;
export const DetachButton = styled(StyledButton)`
  background-color: #e55760;
  max-width: 30%;
`;
export const EnableButton = styled(StyledButton)`
  background-color: #00c656;
  max-width: 30%;
`;
export const DisableButton = styled(StyledButton)`
  background-color: #e55760;
  max-width: 30%;
`;
