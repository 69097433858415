/* @flow */
import { AlertIcon, StarsIcon } from 'Icons';
import { fetchErrors } from 'actions';
import { Popconfirm } from 'antd';
import {
  DonorItem,
  DonorItemLink,
  DownloadButton,
  StyledTable,
} from 'containers/styled';
import { differenceInMonths, format, parse } from 'date-fns';
import locale from 'date-fns/locale/ru';
import fileDownload from 'js-file-download';
import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { PaymentMethod, currency, decrypt } from 'utils';
import { toSearch } from 'utils/link';
import { getOffset } from 'utils/pagination';
import { Get } from 'utils/requester';
import {
  AlertContainer,
  Footer,
  Months,
  StyledDonorItem,
} from './styled';

type ErrorState = {
  count: number,
  offset: number,
  fileLoading: boolean,
};

type ErrorProps = {
  errors: OrderType[],
  privateKey: string,
  isSearch: boolean,
  limit: number,
  loading: boolean,
  dateRange: {
    startDate: string,
    endDate: string,
  },
  count: number,
  dispatch(Object): void,
};

const getRegularColumns = ({ privateKey }) => [
  {
    title: '',
    width: 40,
  },
  {
    title: () => <b>Дата</b>,
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date) => <DonorItem>{format(parse(date), 'DD MMM YYYY, HH:mm', { locale })}</DonorItem>,
    width: 180,
  },
  {
    title: () => <b>Сумма</b>,
    dataIndex: 'gross_sum',
    key: 'gross_sum',
    render: (sum, item) => (
      <StyledDonorItem>
        {item.message && (
          <AlertContainer data-tip={item.message}>
            <AlertIcon />
            <ReactTooltip place="bottom" type="dark" effect="solid" />
          </AlertContainer>
        )}
        <b>{`${item.gross_sum} ${currency[item.gross_curr.toUpperCase()] || ''}`}</b>
      </StyledDonorItem>
    ),
    width: 100,
  },
  {
    title: () => <b>Телефон</b>,
    dataIndex: 'phone',
    key: 'phone',
    render: (phone) => (
      <DonorItem>{decrypt(phone, privateKey) || '-'}</DonorItem>
    ),
    ellipsis: true,
  },
  {
    title: () => <b>Электронная почта </b>,
    dataIndex: 'email',
    key: 'email',
    render: (email) => (
      <DonorItemLink to={toSearch(email)} isLink>
        {decrypt(email, privateKey)}
      </DonorItemLink>
    ),
    ellipsis: true,
  },
  {
    title: () => <b>Способ</b>,
    dataIndex: 'service',
    key: 'service',
    render: (service) => <DonorItem>{PaymentMethod[service]}</DonorItem>,
    width: 100,
  },
  {
    title: () => <b>Вид донора</b>,
    dataIndex: '',
    render: (_, item) => (
      <DonorItem>
        {item.recurrent && <StarsIcon />}
        {item.recurrent && <Months>{item.transactionCount}</Months>}
        {item['recurrentModel.createdAt']
          && `(${
            differenceInMonths(new Date(), item['recurrentModel.createdAt']) + 1
          } мес)`}
        {!item.recurrent && 'Единоразовый'}
      </DonorItem>
    ),
    width: 140,
  },
];

class Error extends React.Component<ErrorProps, ErrorState> {
  constructor(props: ErrorProps) {
    super(props);
    this.state = {
      fileLoading: false,
    };
  }

  handlePageClick = (page, limit) => {
    const { dateRange, dispatch } = this.props;
    const offset = getOffset(page, limit);
    dispatch(fetchErrors({ dateRange, limit, offset }));
  }

  downloadFile = async () => {
    this.setState({ fileLoading: true });
    const file = await Get('/admin/error/list');
    fileDownload(file.data, 'errors.csv');
    this.setState({ fileLoading: false });
  }

  render() {
    const {
      errors, privateKey, loading, count,
    } = this.props;
    const { fileLoading } = this.state;
    return (
      <div>
        <StyledTable
          pagination={{
            defaultPageSize: 50,
            total: count,
            onChange: this.handlePageClick,
          }}
          columns={getRegularColumns({
            privateKey,
            search: (item) => this.searchDonor(item),
          })}
          dataSource={errors}
          loading={loading}
        />
        <Footer>
          <Popconfirm title="Точно скачать? Это займет продолжительное время и может привести к ошибке!" okText="Да" cancelText="Нет" onConfirm={this.downloadFile}>
            <DownloadButton
              loading={fileLoading}
            >
              Скачать ошибки

            </DownloadButton>
          </Popconfirm>
        </Footer>
      </div>
    );
  }
}

export default connect((state: FullStore): { pageCount: number } => ({
  count: state.errors.count,
  loading: state.errors.loading,
}))(Error);
