/* @flow */
import { PlusIcon, WaitIcon } from 'Icons';
import { addHoldEmail, fetchHoldEmails } from 'actions';
import { Checkbox } from 'antd';
import RemoveHoldEmail from 'components/Modals/RemoveHoldEmail';
import { DonorItemLink, StyledTable, Title } from 'containers/styled';
import { format, parse } from 'date-fns';
import locale from 'date-fns/locale/ru';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { decrypt } from 'utils';
import { toSearch } from 'utils/link';

import {
  AddEmail,
  ContentContainer,
  ErrorMsg,
  Input,
  InputLine,
  OuterContainer,
  OuterWrapper,
  Wrapper,
} from './styled';

type EmailListProps = {
  privateKey: string,
  errorMsg: string,
  pending: boolean,
  emails: Array<EmailType>,
  fetchHoldEmails(): void,
  addNewEmail(string[]): void,
  history: {
    push(string): void,
  },
};

type EmailListState = {
  focus: boolean,
  newEmail: string,
  editedEmail: string,
  editedEmailId: number | null,
  modalVisible: boolean,
};

const getColumns = (privateKey) => [
  {
    title: () => <b>Дата</b>,
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (date) => format(parse(date), 'DD MMM YYYY, HH:mm', { locale }),
    width: 180,
  },
  {
    title: () => <b>Ошибки</b>,
    dataIndex: 'holdService',
    key: 'holdService',
    render: (checked) => <Checkbox checked={checked} />,
    width: 180,
  },
  {
    title: () => <b>Спасибо</b>,
    dataIndex: 'holdSuccess',
    key: 'holdSuccess',
    render: (checked) => <Checkbox checked={checked} />,
    width: 180,
  },
  {
    title: () => <b>Электронная почта</b>,
    dataIndex: 'email',
    key: 'email',
    render: (email) => (
      <DonorItemLink to={toSearch(email)} isLink>
        {decrypt(email, privateKey)}
      </DonorItemLink>
    ),
    ellipsis: true,
  },
];

class EmailList extends React.Component<EmailListProps, EmailListState> {
  constructor(props: EmailListProps) {
    super(props);
    this.state = {
      focus: false,
      newEmail: '',
      email: null,
      modalVisible: false,
    };
  }

  /* ::
  addNewEmail: (string[]) => void;
  hideModal: () => void;
  */

  componentDidMount() {
    const { fetchHoldEmails: FetchHoldEmails } = this.props;
    FetchHoldEmails();
  }

  reflectToState = (k: string, v: string) => {
    this.setState({
      [k]: v,
    });
  };

  showModal = (email) => {
    this.setState({
      email,
      modalVisible: true,
    });
  };

  hideModal = () => {
    this.setState({
      email: null,
      modalVisible: false,
    });
  };

  addNewEmail = (e: Event) => {
    e.preventDefault();

    const { newEmail } = this.state;
    const { addNewEmail } = this.props;
    let emails;

    if (newEmail.indexOf(',') > 0) {
      emails = newEmail.split(',');
    } else {
      emails = [newEmail];
    }
    addNewEmail(emails);
    this.setState({
      newEmail: '',
    });

    return false;
  };

  render() {
    const {
      newEmail,
      modalVisible,
      email,
      focus,
    } = this.state;
    const {
      emails = [], pending, errorMsg, privateKey,
    } = this.props;
    return (
      <OuterWrapper>
        <OuterContainer>
          <Wrapper>
            <Title>Холд список</Title>
            <ContentContainer>
              <InputLine onSubmit={this.addNewEmail}>
                <AddEmail isPending={pending} isFocus={focus}>
                  {pending ? <WaitIcon /> : <PlusIcon />}
                </AddEmail>
                <Input
                  type="text"
                  placeholder="Email для добавления"
                  onChange={(e: { target: { value: string } }) => this.reflectToState('newEmail', e.target.value)}
                  onFocus={() => this.setState({ focus: true })}
                  onBlur={() => this.setState({ focus: false })}
                  value={pending ? 'Подождите...' : newEmail}
                  readOnly={pending}
                />
              </InputLine>
              {errorMsg && (
                <ErrorMsg>
                  При последнем добавлении произошла ошибка:
                  <br />
                  {' '}
                  {errorMsg}
                </ErrorMsg>
              )}
            </ContentContainer>
            <StyledTable
              columns={getColumns(privateKey)}
              dataSource={emails}
              onRow={(record) => ({
                onClick: () => this.showModal(record),
              })}
            />
            {email && (
            <RemoveHoldEmail
              email={email}
              hideFn={this.hideModal}
              visible={modalVisible}
            />
            )}
          </Wrapper>
        </OuterContainer>
      </OuterWrapper>
    );
  }
}

const connectedEmailList = connect(
  (state: {
    holdEmails: {
      emails: Array /*:: <EmailType> */,
      addNewEmailPending: boolean,
      errorMsg: string,
    },
    privateKey: { value: string },
  }) => ({
    emails: state.holdEmails.emails,
    pending: state.holdEmails.addNewEmailPending,
    errorMsg: state.holdEmails.errorMsg,
    privateKey: state.privateKey.value,
  }),
  (dispatch: (Object) => void) => ({
    fetchHoldEmails: () => dispatch(fetchHoldEmails()),
    addNewEmail: (email: string) => dispatch(addHoldEmail(email)),
  }),
)(EmailList);

export default withRouter(connectedEmailList);
