import { QrcodeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background-color: #121212;
  display: flex;
  font-family: 'Graphik LC', sans-serif;
  height: 66px;
  min-height: 66px;
  padding: 0 40px;
  position: relative;
`;

export const Logo = styled(Link)`
  border-right: 1px solid #222;
  color: #fff;
  font-size: 33px;
  font-weight: bold;
  padding-right: 20px;
  text-decoration: none;
`;

export const Menu = styled.nav`
  padding: 0 20px;
`;

export const ExitWrapper = styled.div`
  align-items: center;
  display: flex;
  text-decoration: none;

  cursor: pointer;
  img {
    align-self: flex-start;
    padding-right: 20px;
  }
`;

export const Exit = styled.span`
  color: #fff;
  font-size: 14px;
`;

export const MenuItem = styled(Link)`
  color: ${({ isSelected }: { isSelected: boolean }) => isSelected ? '#797979' : '#fff'};
  font-size: 14px;
  font-weight: bold;
  padding-left: 20px;
  text-decoration: none;
  transition: color 0.2s;

  &:hover {
    color: #797979;
  }
`;

export const MenuButton = styled.button`
  border: none;
  border-radius: 0;
  background-color: #393939;
  color: #fff;
  cursor: pointer;
  font: bold 14px 'Graphik LC', sans-serif;
  margin-left: 20px;
  padding: 13px 20px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #444;
  }
`;
export const Key = styled.img`
  margin-right: 10px;
  height: 20px;

  cursor: pointer;
`;

export const BigRedButton = styled(Key)``;

export const TwoFA = styled(QrcodeOutlined)`
  color: #fff;
  cursor: pointer;
  font-size: 22px;
  margin: 0 10px 0 0;
`;

export const CrutchNavItem = styled(Link)`
  margin-right: 7px;
  cursor: pointer;
  display: flex;

  svg {
    width: 20px;
    height: 21px;
    margin-top: 2px;
  }
`;

export const ButtonsContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;
