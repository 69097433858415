/* eslint-disable */
import React from 'react';
import { message } from 'antd';
import copy from 'copy-to-clipboard';
import { CopyContainer } from './styled';

const onCopy = (text) => {
  copy(text);
  message.success('Успешно скопировано!');
}

export const Copy = ({ children, text }) => (
  <CopyContainer
    role="button"
    tabIndex={0}
    onKeyPress={() => onCopy(text)}
    onClick={() => onCopy(text)}
  >
    {children}
  </CopyContainer>
);
