import styled from 'styled-components';
import { Content, Title } from '../styled';

export const StyledContent = styled(Content)`
  max-width: 520px;
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 20px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const Left = styled.span`
  cursor: pointer;
  font-family: 'Fira Mono', sans-serif;
  font-size: 11px;
  line-height: 1.64;
  flex-basis: 40%;
  opacity: 0.5;
`;

export const Right = styled.span`
  cursor: pointer;
  font-family: 'Fira Mono', sans-serif;
  font-size: 11px;
  line-height: 1.64;
  flex-basis: 60%;
  overflow: hidden;
`;

export const Sum = styled(Right)`
  font-weight: bold;
  flex-basis: auto;
`;

export const ActiveSubscription = styled(Right)`
  color: #00c656;
`;

export const CancelledSubscription = styled(Right)`
  color: #f1485d;
`;

export const Input = styled.input`
  box-sizing: border-box;
  height: 50px;
  width: 140px;
  padding: 10px 10px;
  font: 12px 'Fira Mono', sans-serif;
`;

export const SaveButton = styled.button`
  font: normal 11px 'Fira Mono', sans-serif;
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 0;
  background: #0036ff;
  width: 100px;
  height: 50px;
  margin-left: 11px;
`;

export const SaveButtonSuccess = styled(SaveButton)`
  background: #e5e5e5;
  color: #333;
`;

export const CancelPaymentButton = styled.button`
  width: 100%;
  border: 1px solid #f1485d;
  background: #fff;
  border-radius: 0;
  display: flex;
  align-content: center;
  justify-content: center;
  font: 11px 'Fira Mono', sans-serif;
  color: #f1485d;
  height: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:disabled {
    color: #999;
    border-color: #999;
  }
`;

export const ConfirmCancelButton = styled(CancelPaymentButton)`
  background: #f1485d;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DeleteSuccessButton = styled(CancelPaymentButton)`
  border-color: #e5e5e5;
  background: #e5e5e5;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SumContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-basis: 60%;
`;

export const SumInput = styled(Input)`
  width: 60px;
  margin-left: 5px;
  outline: none;
  border-color: ${({ isSmallSum }: { isSmallSum: boolean }) => isSmallSum ? '#F1485D' : ''};
`;
