/* eslint-disable react/prop-types */

import { Tabs } from 'antd';
import { RegionMailings } from 'components/Mailings/RegionMailings';
import { Wrapper } from 'containers/styled';
import * as React from 'react';
import { connect } from 'react-redux';
import { StyledContainer } from './styled';

const { TabPane } = Tabs;

const Mailings = (props) => {
  const {
    privateKey,
    ru,
    by,
    ca,
    dispatch,
  } = props;
  return (
    <Wrapper>
      <StyledContainer>
        <Tabs type="card">
          <TabPane tab="RU" key={1}>
            <RegionMailings
              dispatch={dispatch}
              subscriber={ru?.subscribers}
              newsletter={ru?.newsletters}
              donors={ru?.donors}
              exSubscriber={ru?.exSubscribers}
              test={ru?.tests}
              privateKey={privateKey}
              unsubscribers={ru?.unsubscribers}
              campaigns={ru?.campaigns}
              region="ru"
            />
          </TabPane>
          <TabPane tab="BY" key={2}>
            <RegionMailings
              dispatch={dispatch}
              subscriber={by?.subscribers}
              newsletter={by?.regionNewsletter}
              donors={by?.donors}
              exSubscriber={by?.exSubscribers}
              test={by?.tests}
              privateKey={privateKey}
              unsubscribers={by?.unsubscribers}
              campaigns={by?.campaigns}
              region="by"
            />
          </TabPane>
          <TabPane tab="CA" key={3}>
            <RegionMailings
              dispatch={dispatch}
              subscriber={ca?.subscribers}
              newsletter={ca?.regionNewsletter}
              donors={ca?.donors}
              exSubscriber={ca?.exSubscribers}
              test={ca?.tests}
              privateKey={privateKey}
              unsubscribers={ca?.unsubscribers}
              campaigns={ca?.campaigns}
              region="ca"
            />
          </TabPane>
        </Tabs>
      </StyledContainer>
    </Wrapper>
  );
};

const mapStateToProps = (
  state: FullStore,
) => ({
  ru: state.mailLists.ru,
  by: state.mailLists.by,
  ca: state.mailLists.ca,
  newsletter: state.mailLists?.newsletters,
  donors: state.mailLists?.donors,
  subscriber: state.mailLists?.subscribers,
  exSubscriber: state.mailLists?.exSubscribers,
  test: state.mailLists?.tests,
  unsubscribers: state.mailLists.unsubscribers || [],
  privateKey: state.privateKey.value || '',
  campaigns: state.mailLists.campaigns,
});

export default connect(mapStateToProps)(Mailings);
