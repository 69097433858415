import { DonorItem } from 'containers/styled';
import styled from 'styled-components';

export const DonorItemStatus = styled(DonorItem)`
  overflow: visible;
`;

export const ContentContainer = styled.div`
  ${''};
`;

export const DateStatus = styled.span`
  color: ${({ color }) => color || 'grey'};
  position: relative;
`;
export const DateStatusAddon = styled.span`
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  text-align: center;
  transform: translateY(100%);
  font-size: 10px;
`;
export const StatusSubscription = styled.span`
  color: ${({ color }) => color || 'grey'};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 10px;
  left: 40px;
`;

export const Months = styled.span`
  color: #00c656;
  margin-right: 10px;
`;
