import * as React from 'react';
import styled from 'styled-components';

export const StarsIcon = () => (
  <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm4.24 16L12 15.45 7.77 18l1.12-4.81-3.73-3.23 4.92-.42L12 5l1.92 4.53 4.92.42-3.73 3.23L16.23 18z" />
  </svg>
);

export const CalendarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M15 1.5c.281 0 .518.096.71.29.194.192.29.429.29.71V15c0 .27-.096.505-.29.703A.953.953 0 0 1 15 16H1a.953.953 0 0 1-.71-.297A.974.974 0 0 1 0 15V2.5c0-.281.096-.518.29-.71A.966.966 0 0 1 1 1.5h4v-1a.48.48 0 0 1 .148-.352A.48.48 0 0 1 5.5 0a.48.48 0 0 1 .352.148A.48.48 0 0 1 6 .5v1h4v-1a.48.48 0 0 1 .148-.352A.48.48 0 0 1 10.5 0a.48.48 0 0 1 .352.148A.48.48 0 0 1 11 .5v1h4zM15 15V2.5h-4V3a.48.48 0 0 1-.148.352.48.48 0 0 1-.352.148.48.48 0 0 1-.352-.148A.48.48 0 0 1 10 3v-.5H6V3a.48.48 0 0 1-.148.352.48.48 0 0 1-.352.148.48.48 0 0 1-.352-.148A.48.48 0 0 1 5 3v-.5H1V15h14zm-3.5-7a.48.48 0 0 1-.352-.148A.48.48 0 0 1 11 7.5v-1a.48.48 0 0 1 .148-.352A.48.48 0 0 1 11.5 6h1a.48.48 0 0 1 .352.148A.48.48 0 0 1 13 6.5v1a.48.48 0 0 1-.148.352A.48.48 0 0 1 12.5 8h-1zm0 4a.48.48 0 0 1-.352-.148A.48.48 0 0 1 11 11.5v-1a.48.48 0 0 1 .148-.352A.48.48 0 0 1 11.5 10h1a.48.48 0 0 1 .352.148.48.48 0 0 1 .148.352v1a.48.48 0 0 1-.148.352.48.48 0 0 1-.352.148h-1zm-3-2a.48.48 0 0 1 .352.148A.48.48 0 0 1 9 10.5v1a.48.48 0 0 1-.148.352A.48.48 0 0 1 8.5 12h-1a.48.48 0 0 1-.352-.148A.48.48 0 0 1 7 11.5v-1a.48.48 0 0 1 .148-.352A.48.48 0 0 1 7.5 10h1zm0-4a.48.48 0 0 1 .352.148A.48.48 0 0 1 9 6.5v1a.48.48 0 0 1-.148.352A.48.48 0 0 1 8.5 8h-1a.48.48 0 0 1-.352-.148A.48.48 0 0 1 7 7.5v-1a.48.48 0 0 1 .148-.352A.48.48 0 0 1 7.5 6h1zm-4 0a.48.48 0 0 1 .352.148A.48.48 0 0 1 5 6.5v1a.48.48 0 0 1-.148.352A.48.48 0 0 1 4.5 8h-1a.48.48 0 0 1-.352-.148A.48.48 0 0 1 3 7.5v-1a.48.48 0 0 1 .148-.352A.48.48 0 0 1 3.5 6h1zm0 4a.48.48 0 0 1 .352.148A.48.48 0 0 1 5 10.5v1a.48.48 0 0 1-.148.352A.48.48 0 0 1 4.5 12h-1a.48.48 0 0 1-.352-.148A.48.48 0 0 1 3 11.5v-1a.48.48 0 0 1 .148-.352A.48.48 0 0 1 3.5 10h1z"
    />
  </svg>
);

export const StatsIcon = () => (
  <svg
    width="11"
    height="10"
    fill="#0036ff"
    version="1.1"
    viewBox="0 -39.375 20.625 18.75"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M5.625,18.75v-18.75h3.75v18.75Zm-5.625,0v-5.625h3.75v5.625Zm11.25,0v-9.375h3.75v9.375Zm5.625,0v-13.125h3.75v13.125Z"
        transform="translate(0, -39.375)"
      />
    </g>
  </svg>
);

export const LeftArrow = () => (
  <svg
    width="19"
    height="11"
    version="1.1"
    viewBox="0 -28.3594 18.75 11.3672"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M5.50781,11.25c-0.078124,0.078125 -0.195312,0.117187 -0.351562,0.117187c-0.15625,0 -0.273438,-0.039062 -0.351562,-0.117187c-3.08594,-3.39844 -4.64844,-5.11719 -4.6875,-5.15625c-0.078126,-0.078125 -0.117188,-0.195313 -0.117188,-0.351563c0,-0.15625 0.039062,-0.273437 0.117188,-0.351562l4.6875,-5.15625c0.234374,-0.234375 0.46875,-0.234375 0.703124,0c0.234376,0.234375 0.234376,0.46875 0,0.703125l-3.92578,4.33594h16.6992c0.3125,0 0.46875,0.15625 0.46875,0.46875c0,0.3125 -0.15625,0.46875 -0.46875,0.46875h-16.6992l3.92578,4.33594c0.234376,0.234375 0.234376,0.46875 0,0.703125Z"
        transform="translate(0, -28.3594)"
      />
    </g>
  </svg>
);

export const RightArrow = () => (
  <svg
    width="19"
    height="11"
    version="1.1"
    viewBox="0 -28.3594 18.75 11.3672"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M13.2422,11.25c-0.234376,-0.234375 -0.234376,-0.46875 0,-0.703125l3.92578,-4.33594h-16.6992c-0.3125,0 -0.46875,-0.15625 -0.46875,-0.46875c0,-0.3125 0.15625,-0.46875 0.46875,-0.46875h16.6992l-3.92578,-4.33594c-0.234376,-0.234375 -0.234376,-0.46875 0,-0.703125c0.234374,-0.234375 0.46875,-0.234375 0.703124,0c3.08594,3.39844 4.64844,5.11719 4.6875,5.15625c0.078126,0.078125 0.117188,0.195312 0.117188,0.351562c0,0.15625 -0.039062,0.273438 -0.117188,0.351563l-4.6875,5.15625c-0.078124,0.078125 -0.195312,0.117187 -0.351562,0.117187c-0.15625,0 -0.273438,-0.039062 -0.351562,-0.117187Z"
        transform="translate(0, -28.3594)"
      />
    </g>
  </svg>
);

export const CloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
    <path fillRule="evenodd" d="M10 1L6 5l4 4-1 1-4-4-4 4-1-1 4-4-4-4 1-1 4 4 4-4z" />
  </svg>
);

export const AlertIcon = () => (
  <svg
    width="13"
    height="13"
    version="1.1"
    viewBox="0 -47.8125 24.375 24.375"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#F1485D">
      <path
        d="M12.1875,0c3.35938,0 6.23047,1.19141 8.61328,3.57422c2.38281,2.38281 3.57422,5.25391 3.57422,8.61328c0,3.35938 -1.19141,6.23047 -3.57422,8.61328c-2.38281,2.38281 -5.25391,3.57422 -8.61328,3.57422c-3.35938,0 -6.23047,-1.19141 -8.61328,-3.57422c-2.38281,-2.38281 -3.57422,-5.25391 -3.57422,-8.61328c0,-3.35938 1.19141,-6.23047 3.57422,-8.61328c2.38281,-2.38281 5.25391,-3.57422 8.61328,-3.57422Zm1.40625,18.2812v-2.34375h-2.8125v2.34375Zm0,-5.15625v-7.5h-2.8125v7.5Z"
        transform="translate(0, -47.8125)"
      />
    </g>
  </svg>
);

export const StripeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <circle cx="10" cy="10" r="10" fill="#6772E5" />
      <path
        fill="#FFF"
        d="M9.187 7.574c0-.515.404-.712 1.071-.712.958 0 2.167.303 3.125.843V4.607A7.981 7.981 0 0 0 10.258 4C7.701 4 6 5.398 6 7.732c0 3.64 4.787 3.06 4.787 4.628 0 .607-.504.805-1.209.805-1.046 0-2.381-.449-3.44-1.055v3.138a8.4 8.4 0 0 0 3.44.752C12.198 16 14 14.642 14 12.281c-.013-3.93-4.813-3.23-4.813-4.707z"
      />
    </g>
  </svg>
);
export const PlusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    width="459.325px"
    height="459.325px"
    viewBox="0 0 459.325 459.325"
    xmlSpace="preserve"
  >
    <g>
      <path d="M459.319,229.668c0,22.201-17.992,40.193-40.205,40.193H269.85v149.271c0,22.207-17.998,40.199-40.196,40.193   c-11.101,0-21.149-4.492-28.416-11.763c-7.276-7.281-11.774-17.324-11.769-28.419l-0.006-149.288H40.181   c-11.094,0-21.134-4.492-28.416-11.774c-7.264-7.264-11.759-17.312-11.759-28.413C0,207.471,17.992,189.475,40.202,189.475h149.267   V40.202C189.469,17.998,207.471,0,229.671,0c22.192,0.006,40.178,17.986,40.19,40.187v149.288h149.282   C441.339,189.487,459.308,207.471,459.319,229.668z" />
    </g>
  </svg>
);

export const PaypalIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <circle cx="10" cy="10" r="10" fill="#023087" />
      <path
        fill="#FFF"
        d="M14.945 8.326c.086.402.066.864-.026 1.366-.428 2.21-1.87 2.973-3.72 2.973h-.29a.46.46 0 0 0-.448.395l-.026.127-.362 2.317-.013.1a.473.473 0 0 1-.454.396H7.953c-.184 0-.303-.154-.277-.342.119-.75.23-1.5.35-2.25l.355-2.243c.02-.16.125-.248.283-.248.263 0 .527-.006.863 0a6.7 6.7 0 0 0 1.553-.14c.712-.16 1.357-.456 1.89-.964.48-.456.803-1.018 1.02-1.648.1-.294.178-.59.231-.89.013-.08.033-.067.08-.034.361.275.565.643.644 1.085zm-1.132-1.889c0 .55-.125 1.072-.303 1.58-.342 1.012-.988 1.735-1.988 2.11a4.995 4.995 0 0 1-1.66.281c-.395.007-.79 0-1.185 0-.428 0-.698.215-.777.643-.092.51-.454 2.88-.56 3.55-.006.046-.026.066-.078.066H5.319a.32.32 0 0 1-.316-.368l1.528-9.85A.533.533 0 0 1 7.05 4h3.937c.283 0 .935.127 1.376.301.935.369 1.449 1.119 1.449 2.136z"
      />
    </g>
  </svg>
);

export const BitcoinIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <circle cx="10" cy="10" r="10" fill="#F7931A" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M13.84 8.503c.175-1.177-.72-1.81-1.946-2.232l.397-1.594-.97-.242-.387 1.552a40.594 40.594 0 0 0-.777-.183l.39-1.562L9.576 4l-.398 1.594a32.463 32.463 0 0 1-.62-.146l.001-.005-1.338-.334-.258 1.036s.72.165.705.176c.393.098.464.358.452.564L7.668 8.7a.804.804 0 0 1 .1.032l-.102-.025-.634 2.544c-.048.12-.17.299-.445.23.01.015-.705-.175-.705-.175l-.482 1.11 1.263.315c.235.059.465.12.692.179l-.402 1.612.97.242.397-1.595c.265.072.522.138.773.2l-.396 1.588.97.242.402-1.61c1.655.314 2.899.187 3.423-1.31.422-1.204-.021-1.9-.892-2.353.634-.146 1.112-.563 1.24-1.424zm-2.218 3.109c-.3 1.205-2.329.553-2.987.39l.533-2.136c.658.164 2.767.489 2.454 1.746zm.3-3.127c-.273 1.096-1.962.54-2.51.403l.483-1.938c.548.137 2.312.392 2.027 1.535z"
      />
    </g>
  </svg>
);
export const WaitIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 60 60"
    xmlSpace="preserve"
    width="512px"
    height="512px"
  >
    <g>
      <path
        d="M57,38.36V36h1c0.553,0,1-0.447,1-1s-0.447-1-1-1h-1h-1H42h-1h-1c-0.553,0-1,0.447-1,1s0.447,1,1,1h1v2.36   c0,3.535,1.845,6.748,4.827,8.64C42.845,48.892,41,52.105,41,55.64V58h-1c-0.553,0-1,0.447-1,1s0.447,1,1,1h1h1h14h1h1   c0.553,0,1-0.447,1-1s-0.447-1-1-1h-1v-2.36c0-3.535-1.845-6.748-4.827-8.64C55.155,45.108,57,41.895,57,38.36z M55,55.64V58H43   v-2.36c0-3.319,2.033-6.298,5.197-7.64h1.605C52.967,49.342,55,52.32,55,55.64z M49.803,46h-1.605C45.033,44.658,43,41.68,43,38.36   V36h12v2.36C55,41.68,52.967,44.658,49.803,46z"
        fill="#FFFFFF"
      />
      <path
        d="M28.555,33.532C29.223,34.451,30.301,35,31.438,35c0.95,0,1.845-0.371,2.518-1.044c0.737-0.737,1.114-1.756,1.033-2.797   s-0.612-1.99-1.459-2.606l-12.944-9.363c-0.396-0.286-0.945-0.242-1.293,0.104c-0.348,0.348-0.391,0.896-0.104,1.293L28.555,33.532   z"
        fill="#FFFFFF"
      />
      <path
        d="M30,0c-0.553,0-1,0.447-1,1v13.292c0,0.553,0.447,1,1,1s1-0.447,1-1V2.018C45.979,2.546,58,14.896,58,30   c0,0.335-0.014,0.667-0.025,1h2C59.986,30.667,60,30.335,60,30C60,13.458,46.542,0,30,0z"
        fill="#FFFFFF"
      />
      <path
        d="M2,30c0-7.46,2.9-14.479,8.166-19.764c0.391-0.392,0.389-1.024-0.002-1.414C9.772,8.434,9.14,8.434,8.75,8.824   C3.107,14.486,0,22.007,0,30c0,16.542,13.458,30,30,30c2.771,0,5.451-0.385,8-1.092v-2.079C35.464,57.587,32.78,58,30,58   C14.561,58,2,45.439,2,30z"
        fill="#FFFFFF"
      />
    </g>
  </svg>
);

export const SbrfIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <circle cx="10" cy="10" r="10" fill="#2A873A" />
      <g fill="#FFF">
        <path d="M13.507 5.322l.492.453L7.787 9.37 4.779 7.615l.282-.566L7.787 8.62l5.72-3.298zm-1.25-.722l.66.34-5.13 2.972-2.39-1.373.408-.495L7.787 7.19l4.47-2.59zm2.178 1.642l.365.495L7.787 10.8 4.4 8.847l.155-.623 3.232 1.868 6.648-3.85z" />
        <path d="M15.487 8.173c.209.57.313 1.169.313 1.795 0 .625-.104 1.233-.313 1.822l-.142.373a5.732 5.732 0 0 1-1.239 1.794 5.74 5.74 0 0 1-1.85 1.187 5.84 5.84 0 0 1-4.526 0 5.999 5.999 0 0 1-1.836-1.187 5.437 5.437 0 0 1-1.239-1.794A5.52 5.52 0 0 1 4.2 9.968v-.373l3.53 1.946L15.174 7.4l.313.773z" />
      </g>
    </g>
  </svg>
);

export const YandexMoneyIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <defs>
      <path id="a" d="M.002.035h8.579V11.2H.002z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle cx="10" cy="10" r="10" fill="#FACA22" />
      <g transform="translate(5.6 3.2)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          fill="#FFF"
          d="M0 5.574c0-.627.053-1.025 1.365-1.994C2.451 2.777 5.933.035 5.933.035v4.577H8.58V11.2H.827A.825.825 0 0 1 0 10.381V5.574"
          mask="url(#b)"
        />
      </g>
      <path
        fill="#FACA22"
        d="M11.504 7.8v2.772L6.6 14l6.2-2.07V7.8h-1.296M9.225 7.556c.258-.337.636-.456.843-.265.207.19.166.617-.093.953-.258.337-.636.455-.843.265-.207-.19-.166-.617.093-.953"
      />
    </g>
  </svg>
);

export const CloudpaymentsIcon = () => (
  <svg width="28" height="26" viewBox="0 0 174 144" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M32.3236 130.111C32.3236 134.552 29.482 137.713 25.6103 137.713C23.337 137.713 21.5965 136.612 20.5308 134.942V144H17.5471V122.83H20.5308V125.316C21.5965 123.647 23.337 122.51 25.6458 122.51C29.4465 122.51 32.3236 125.636 32.3236 130.111ZM29.3044 130.111C29.3044 127.341 27.4929 125.387 24.9709 125.387C22.4134 125.387 20.4953 127.341 20.4953 130.111C20.4953 132.918 22.4134 134.836 24.9709 134.836C27.4929 134.836 29.3044 132.918 29.3044 130.111Z" fill="#6496DC" />
      <path d="M49.0182 122.83V137.393H46.0345V134.942C44.9689 136.612 43.2284 137.713 40.9551 137.713C37.0478 137.713 34.2417 134.552 34.2417 130.111C34.2417 125.671 37.1189 122.51 40.9551 122.51C43.2639 122.51 45.0044 123.647 46.07 125.316V122.83H49.0182ZM46.07 130.111C46.07 127.341 44.1519 125.387 41.5945 125.387C39.037 125.387 37.261 127.341 37.261 130.111C37.261 132.918 39.0725 134.836 41.5945 134.836C44.1164 134.836 46.07 132.918 46.07 130.111Z" fill="#6496DC" />
      <path d="M65.4998 122.83L55.8737 144H52.6769L56.5131 135.511L50.7588 122.794H53.9556L58.076 132.278L62.3029 122.794L65.4998 122.83Z" fill="#6496DC" />
      <path d="M89.0854 128.016V137.358H86.1016V128.655C86.1016 126.488 85.2136 125.352 83.2245 125.352C80.8801 125.352 79.6369 127.163 79.6369 129.827L79.6724 137.358H76.6532V128.655C76.6532 126.488 75.7652 125.352 73.776 125.352C71.4317 125.352 70.224 127.163 70.224 129.827V137.358H67.2403V122.794H70.224V125.139C71.2186 123.469 72.6749 122.475 74.7351 122.475C76.9729 122.475 78.5358 123.576 79.2462 125.529C80.2408 123.647 81.8747 122.475 84.1835 122.475C87.2383 122.51 89.0854 124.57 89.0854 128.016Z" fill="#6496DC" />
      <path d="M106.171 130.928H94.3424C94.591 133.593 96.4736 135.084 98.889 135.084C100.63 135.084 102.086 134.338 102.796 132.918H105.887C104.75 136.186 101.979 137.713 98.8535 137.713C94.4489 137.713 91.2876 134.552 91.2876 130.147C91.2876 125.742 94.4134 122.51 98.818 122.51C103.507 122.51 106.455 126.133 106.171 130.928ZM94.52 128.478H103.009C102.548 126.488 101.056 125.103 98.8535 125.103C96.5447 125.103 95.0173 126.524 94.52 128.478Z" fill="#6496DC" />
      <path d="M121.551 128.193V137.393H118.567V128.797C118.567 126.559 117.573 125.352 115.406 125.352C112.849 125.352 111.463 127.021 111.463 129.827V137.358H108.48V122.794H111.463V125.21C112.493 123.505 114.056 122.475 116.33 122.475C119.562 122.51 121.551 124.641 121.551 128.193Z" fill="#6496DC" />
      <path d="M135.084 136.647C134.303 137.109 132.598 137.713 130.928 137.713C127.945 137.713 125.6 135.83 125.6 131.816V125.636H122.901V122.83H125.6V119.526L128.584 118.852V122.83H134.552V125.636H128.584V131.639C128.584 133.77 129.579 134.907 131.461 134.907C132.385 134.907 133.415 134.587 134.552 134.196L135.084 136.647Z" fill="#6496DC" />
      <path d="M136.114 132.704H139.169C139.631 134.303 140.803 135.049 143.325 135.049C145.243 135.049 146.451 134.481 146.451 133.379C146.451 132.172 145.066 131.959 142.65 131.39C138.565 130.396 136.86 129.579 136.86 127.128C136.86 124.18 139.347 122.51 143.005 122.51C146.735 122.51 148.831 124.286 149.364 126.915H146.344C145.918 125.707 144.817 125.139 142.97 125.139C141.052 125.139 139.915 125.814 139.915 126.808C139.915 127.732 140.803 127.98 143.361 128.584C147.303 129.508 149.577 130.183 149.577 133.024C149.577 136.15 146.771 137.677 143.325 137.677C139.418 137.677 136.683 135.83 136.114 132.704Z" fill="#6496DC" />
      <path d="M8.27627 72.4973C11.0113 58.4312 23.3725 47.9526 38.149 47.9526C44.3651 47.9526 50.368 49.8707 55.3764 53.2807L61.2373 47.4198C56.6552 44.0099 51.3271 41.63 45.7148 40.4933C47.3843 22.2713 62.7647 8.16971 81.2708 8.16971C88.5881 8.16971 95.6211 10.372 101.624 14.5279C106.81 18.1154 110.93 22.9817 113.594 28.6295C106.455 30.5121 99.8481 33.9931 94.3069 38.8239L100.097 44.6137C107.201 38.6108 116.259 35.2008 125.742 35.2008C146.842 35.2008 164.105 51.5402 165.383 72.4973H173.553C172.949 60.2782 167.728 48.8407 158.883 40.3513C149.932 31.7908 138.175 27.0311 125.742 27.0311C124.464 27.0311 123.185 27.1021 121.906 27.2087C118.674 19.3587 113.275 12.6453 106.277 7.8145C98.9245 2.69956 90.2575 0 81.2708 0C58.5022 0 39.6053 17.334 37.5451 39.8185C18.5417 40.1026 2.80612 54.0622 0 72.4973H8.27627Z" fill="#6496DC" />
      <path d="M16.2684 95.0528C16.2684 86.812 22.3779 80.5604 30.7607 80.5604C37.5451 80.5604 43.5481 84.6453 44.6137 91.7139H37.1899C36.3374 88.6946 33.7089 87.2028 30.7607 87.2028C26.3562 87.2028 23.5501 90.4351 23.5856 95.0173C23.5501 99.6705 26.4627 102.832 30.6897 102.832C33.6734 102.832 36.3019 101.304 37.1544 98.2852H44.5782C43.4771 105.602 37.3675 109.51 30.6897 109.51C22.3779 109.545 16.2684 103.294 16.2684 95.0528Z" fill="#393962" />
      <path d="M47.9882 72.5328H55.1988V108.941H47.9882V72.5328Z" fill="#393962" />
      <path d="M87.9842 95.0528C87.9842 103.436 81.6261 109.545 73.4919 109.545C65.3932 109.545 59.035 103.4 59.035 95.0528C59.035 86.67 65.4998 80.5604 73.4919 80.5604C81.5195 80.5604 87.9842 86.67 87.9842 95.0528ZM66.3167 95.0528C66.3167 99.6349 69.4425 102.69 73.4919 102.69C77.5412 102.69 80.667 99.6705 80.667 95.0528C80.667 90.5062 77.5412 87.4159 73.4919 87.4159C69.4781 87.4159 66.3167 90.5062 66.3167 95.0528Z" fill="#393962" />
      <path d="M117.253 81.1643V108.941H110.042V105.105C108.018 107.947 105.283 109.545 101.446 109.545C95.3369 109.545 91.5363 105.354 91.5363 98.5693V81.1288H98.7469V96.9709C98.7469 100.594 100.097 102.619 103.862 102.619C107.84 102.619 110.007 100.239 110.007 95.6922V81.0932H117.253V81.1643Z" fill="#393962" />
      <path d="M150.074 72.5328V108.941H142.863V105.212C140.945 107.84 138.033 109.545 134.125 109.545C126.915 109.545 121.444 103.578 121.444 95.0528C121.444 86.4568 126.95 80.5604 134.054 80.5604C137.997 80.5604 140.91 82.372 142.828 85.0715V72.5683H150.074V72.5328ZM142.828 95.0528C142.828 90.5062 139.738 87.4159 135.724 87.4159C131.674 87.4159 128.691 90.5062 128.691 95.0528C128.691 99.6349 131.71 102.69 135.724 102.69C139.773 102.69 142.828 99.6349 142.828 95.0528Z" fill="#393962" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="173.553" height="144" fill="white" />
      </clipPath>
    </defs>
  </svg>

);
export const Crutch = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    width="512px"
    height="512px"
    viewBox="0 0 31.949 31.949"
    xmlSpace="preserve"
  >
    <g>
      <path
        d="M30.348,8.395l-9.169-8.046c-0.689-0.607-1.861-0.4-2.616,0.46c-0.756,0.859-0.809,2.05-0.119,2.656l0.762,0.668   l-9.32,10.618l-1.443,7.81l-4.097,4.668l-1.007-0.884c-0.234-0.205-0.54-0.31-0.85-0.289c-0.31,0.02-0.6,0.163-0.805,0.396   l-0.276,0.315c-0.427,0.486-0.378,1.228,0.108,1.655L5.2,31.658c0.487,0.427,1.228,0.379,1.655-0.106l0.276-0.315   c0.427-0.487,0.379-1.228-0.107-1.655l-1.005-0.883l4.096-4.667l7.485-2.519l9.314-10.613l0.698,0.612   c0.69,0.606,1.863,0.399,2.619-0.46C30.986,10.189,31.038,9,30.348,8.395z M16.319,19.593l-3.051,1.025   c-0.49,0.165-1.03,0.059-1.42-0.279c-0.39-0.339-0.572-0.858-0.478-1.366l0.593-3.213l6.359-7.245l4.36,3.828L16.319,19.593z    M23.417,11.504l-4.358-3.826l1.503-1.713l4.358,3.827L23.417,11.504z"
        fill="#FFFFFF"
      />
    </g>
  </svg>
);

export const AllDoneIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="10" viewBox="0 0 18 10">
    <path
      fill="#333"
      fillRule="evenodd"
      d="M0 5.886l1.044-1.062L5.238 9l-1.062 1.062L0 5.886zM16.344 0l1.08 1.062-9 9-4.212-4.176L5.31 4.824l3.114 3.132L16.344 0zm-3.168 1.062L8.424 5.85 7.38 4.788 12.114 0l1.062 1.062z"
    />
  </svg>
);

export const BackupIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15">
    <path
      fill="#0036FF"
      fillRule="evenodd"
      d="M9 .95c1.224 0 2.358.306 3.402.918 1.02.6 1.83 1.41 2.43 2.43A6.609 6.609 0 0 1 15.75 7.7a6.609 6.609 0 0 1-.918 3.402 6.739 6.739 0 0 1-2.43 2.43A6.609 6.609 0 0 1 9 14.45a6.619 6.619 0 0 1-4.104-1.404l1.044-1.062c.456.3.942.534 1.458.702.516.168 1.05.252 1.602.252.96 0 1.842-.234 2.646-.702a5.283 5.283 0 0 0 1.89-1.89 5.166 5.166 0 0 0 .702-2.646c0-.96-.234-1.842-.702-2.646a5.283 5.283 0 0 0-1.89-1.89A5.166 5.166 0 0 0 9 2.462c-.96 0-1.842.234-2.646.702a5.283 5.283 0 0 0-1.89 1.89A5.166 5.166 0 0 0 3.762 7.7h2.25l-3.024 2.988L0 7.7h2.25c0-1.224.306-2.358.918-3.402.6-1.02 1.41-1.83 2.43-2.43A6.609 6.609 0 0 1 9 .95zm1.512 6.75c0 .408-.15.762-.45 1.062-.3.3-.654.45-1.062.45-.408 0-.762-.15-1.062-.45-.3-.3-.45-.654-.45-1.062 0-.408.15-.762.45-1.062.3-.3.654-.45 1.062-.45.408 0 .762.15 1.062.45.3.3.45.654.45 1.062z"
    />
  </svg>
);

export const Loader = styled.span`
  display: inline-block;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  border-radius: 50%;
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  border: 0.25rem solid rgba(204, 204, 204, 0.5);
  border-top-color: rgb(0, 0, 0);
  animation: spin 1s infinite linear;
  border-style: double;
  border-width: 0.5rem;
`;
