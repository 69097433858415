import { Loader } from 'Icons';
import { DonorItem } from 'containers/styled';
import styled from 'styled-components';

export const LoaderStyled = styled(Loader)`
  margin-right: 10px;
`;

export const ContentContainer = styled.div`
  margin-bottom: 30px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 8px;
  left: 40px;
`;

export const ContainerTitle = styled.div`
  font: bold 16px 'Graphik LC', sans-serif;
  padding-bottom: 17px;
  padding-top: 24px;
`;

export const AlertContainer = styled.div`
  left: -20px;
  top: 0px;
  position: absolute;

  svg {
    margin-right: 0;
  }
`;

export const StyledDonorItem = styled(DonorItem)`
  overflow: visible;
`;

export const Months = styled.span`
  color: #00c656;
  margin-right: 10px;
`;
