/* @flow */
/* eslint-disable react/jsx-props-no-spreading */
import { CalendarIcon, CloseIcon } from 'Icons';
import type moment from 'moment';
import * as React from 'react';
import { DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { connect } from 'react-redux';
import {
  Container,
  DateContainer,
  GreenWrapper,
  IconContainer,
  ModalWrapper,
  Wrapper,
} from './styled';

const defaultProps = {
  renderDay: null,
  minimumNights: 1,
  isDayBlocked: () => false,
  // isOutsideRange: day => !isInclusivelyAfterDay(day, moment()),
  isDayHighlighted: () => false,
  enableOutsideDays: false,

  // calendar presentation and interaction related props
  // orientation: HORIZONTAL_ORIENTATION,
  withPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 2,
  onOutsideClick() {},
  keepOpenOnDateSelect: false,
  renderCalendarInfo: null,
  isRTL: false,

  // navigation related props
  navPrev: null,
  navNext: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},

  // internationalization
  monthFormat: 'MMMM YYYY',
};

type CalendarState = {
  isCalendarOpen: boolean,
  startDate: ?string | moment,
  endDate: ?string | moment,
  focusedInput: 'startDate' | 'endDate',
};

type CalendarProps = {
  changeDate: (Object) => void,
  isDateSort: boolean,
};

class Calendar extends React.Component<CalendarProps, CalendarState> {
  constructor(props: CalendarProps) {
    super(props);
    this.state = {
      isCalendarOpen: false,
      startDate: null,
      endDate: null,
      focusedInput: 'startDate',
    };
  }

  onClose= () => {
    const { startDate, endDate } = this.state;
    const { changeDate } = this.props;
    if (startDate && endDate) {
      changeDate({
        dateRange: {
          startDate: startDate.startOf('day').get(),
          endDate: endDate.endOf('day').get(),
        },
        isDateSort: true,
      });
    }
    this.setState((state) => ({
      isCalendarOpen: !state.isCalendarOpen,
    }));
  }

  handleClick = () => {
    const { isCalendarOpen } = this.state;
    this.setState(() => ({
      isCalendarOpen: !isCalendarOpen,
    }));
  }

  handleClose = () => {
    const { changeDate } = this.props;
    this.setState(
      () => ({
        startDate: null,
        endDate: null,
      }),
      () => changeDate({
        dateRange: { startDate: null, endDate: null },
        isDateSort: false,
      }),
    );
  }

  render() {
    const { isDateSort } = this.props;
    const {
      isCalendarOpen, startDate, endDate, focusedInput,
    } = this.state;
    return (
      <Container>
        {isDateSort ? (
          <IconContainer>
            <GreenWrapper onClick={this.handleClose}>
              <CloseIcon />
            </GreenWrapper>
          </IconContainer>
        ) : (
          <IconContainer>
            <Wrapper onClick={this.handleClick}>
              <CalendarIcon />
            </Wrapper>
          </IconContainer>
        )}
        {isCalendarOpen && [
          <DateContainer key={1}>
            <DayPickerRangeController
              {...defaultProps}
              startDate={startDate}
              endDate={endDate}
              onDatesChange={({
                startDate: start,
                endDate: end,
              }: {
                startDate: moment,
                endDate: moment,
              }) => {
                this.setState(() => ({ startDate: start, endDate: end }));
              }}
              focusedInput={focusedInput}
              onFocusChange={(focused: 'startDate' | 'endDate') => this.setState(() => ({
                focusedInput: focused || 'startDate',
              }))}
            />
          </DateContainer>,
          <ModalWrapper key={2} onClick={this.onClose} />,
        ]}
      </Container>
    );
  }
}

export default connect()(Calendar);
