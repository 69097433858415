/* @flow */
/* eslint-disable import/prefer-default-export */
export const toSearch = (
  str: string,
  type: string = 'email',
) => `/search/?t=${type}&q=${encodeURIComponent(str)}`;

export const toExternalServiceRecurrent = (id, service) => {
  if (service === 'stripe') {
    return `https://dashboard.stripe.com/customers/${id}`;
  }
  return undefined;
};
