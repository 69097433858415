import { privateKeySet } from 'actions';
import { Modal } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Content, RSAField, SubmitButton } from '../styled';

type Props = {
  setKey: (string) => void,
  hideFn: () => void,
  visible: boolean,
};

const RsaPrivate = (props: Props) => {
  const [key, setPrivateKey] = useState('');
  const { setKey, hideFn, visible } = props;
  return (
    <Modal title="Приватный ключ" footer={null} centered visible={visible} onCancel={hideFn}>
      <Content>
        <RSAField value={key} onChange={(e) => setPrivateKey(e.target.value)} />
        <SubmitButton
          onClick={() => {
            setKey(key);
            hideFn();
          }}
        >
          Добавить
        </SubmitButton>
      </Content>
    </Modal>
  );
};

export default connect(
  (props) => ({ ...props }),
  (dispatch) => ({ setKey: (d) => dispatch(privateKeySet(d)) }),
)(RsaPrivate);
