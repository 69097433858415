import { Button, Table } from 'antd';
import styled from 'styled-components';

export const Selector = styled.div`
  display: flex;
  justify-content: start;
  margin-bottom: 20px;
`;

export const StyledButton = styled(Button)`
  margin-right: 15px;
`;

export const StyledTable = styled(Table)`
  padding: 20px 0 20px;
`;
