/* @flow */
/* eslint-disable import/prefer-default-export */
import * as React from 'react';
import { Container, Wrapper } from './styled';

type Props = {
  children: React.ElementType,
};

export const Main = (props: Props) => {
  const { children } = props;
  return (
    <Wrapper>
      <Container>
        {children}
      </Container>
    </Wrapper>
  );
};
