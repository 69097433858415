import * as Sentry from '@sentry/react';
import {
  USER_LOGIN,
  USER_LOGIN_FAILURE,
  USER_LOGIN_OTP,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_SESSION_CHECK,
  USER_SESSION_EXPIRED,
} from 'actions/constants';
import { sendNotification } from 'utils/notification';

export const user = (state = {}, action: Action) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        status: 'Logging',
      };
    case USER_LOGIN_OTP:
      return {
        ...state,
        ...action.data,
        status: 'logged_otp',
      };
    case USER_LOGIN_SUCCESS:
      if (action.user.token) {
        localStorage.setItem('token', action.user.token);
      }
      Sentry.configureScope((scope) => {
        scope.setUser({ name: action.user.username, id: action.user.id });
        scope.setExtra('roleId', action.user.role.id);
      });
      return {
        ...action.user,
        status: 'logged',
      };
    case USER_LOGIN_FAILURE:
      sendNotification('error', 'Ошибка', 'Неверный логин или пароль');
      return {
        status: 'login_failure',
      };
    case USER_LOGOUT:
      return {
        status: 'not_logged',
      };
    case USER_SESSION_CHECK:
      return {
        status: 'check_session',
      };
    case USER_SESSION_EXPIRED:
      return {
        status: 'session_expired',
      };
    default:
      return state;
  }
};

export default user;
