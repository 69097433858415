/* eslint-disable import/prefer-default-export, no-restricted-syntax, no-loop-func */
import moment from 'moment';

const OrderStatus = {
  success: 'success',
  fail: 'fail',
  pending: 'pending',
  refunded: 'refunded',
};

export const isPaymentSuccess = (recurrent, today) => {
  const lastPayment = moment(recurrent.order?.createdAt);
  if (lastPayment.isSame(today, 'date') && recurrent.order?.status === OrderStatus.success) {
    return true;
  }
  return false;
};

export const isPaymentFailure = (recurrent, today) => {
  const lastPayment = moment(recurrent.order?.createdAt);
  if (lastPayment.isSame(today, 'date') && recurrent.order?.status === OrderStatus.fail) {
    return true;
  }
  return false;
};

export const isPaymentPending = (recurrent, today) => {
  const lastPayment = moment(recurrent.order?.createdAt);
  if (lastPayment.isSame(today, 'date') && recurrent.order?.status === OrderStatus.pending) {
    return true;
  }
  return false;
};

export const isPaymentRefunded = (recurrent, today) => {
  const lastPayment = moment(recurrent.order?.createdAt);
  if (lastPayment.isSame(today, 'date') && recurrent.order?.status === OrderStatus.refunded) {
    return true;
  }
  return false;
};

export const getPlannedTotals = (totals) => {
  const days = Object.keys(totals);
  let planned = 0;
  let actual = 0;
  let failed = 0;
  for (const day of days) {
    const orders = totals[day];
    const today = moment(day, 'DD.MM.YYYY');
    orders?.forEach((recurrent) => {
      planned += recurrent.gross_sum;
      if (isPaymentSuccess(recurrent, today)) {
        actual += recurrent.gross_sum;
      }
      if (isPaymentFailure(recurrent, today)) {
        failed += recurrent.gross_sum;
      }
    });
  }
  return { planned, actual, failed };
};
