/* @flow */
/* eslint-disable no-console, camelcase */
import { ExclamationCircleFilled } from '@ant-design/icons';
import Auth from 'Auth';
import {
  cancelSubscription,
  changePaymentSum,
  changeSubscriptionDate,
} from 'actions/index';
import { Modal, Popconfirm, message } from 'antd';
import { Copy } from 'components/Copy';
import { ROLES } from 'constants.js';
import { format } from 'date-fns';
import locale from 'date-fns/locale/ru';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { PaymentMethod, currency, decrypt } from 'utils';
import {
  ActiveSubscription,
  CancelPaymentButton,
  CancelledSubscription,
  ConfirmCancelButton,
  DeleteSuccessButton,
  Input,
  Left,
  Right,
  Row,
  SaveButton,
  SaveButtonSuccess,
  StyledContent,
  StyledTitle,
  Sum,
  SumContainer,
  SumInput,
} from './styled';

type EditDonorState = {
  cancelStart: boolean,
  cancelConfirm: boolean,
  date: string,
  dateSaved: boolean,
  sum: string,
  sumSaved: boolean,
};

type EditDonorProps = {
  donor: RecurrentType,
  hide: () => void,
  dispatch: (Object) => void,
  privateKey: string,
  visible: boolean,
  searchType: string,
};

const dateMask = 'DD.MM.YYYY H:mm';

class EditDonor extends React.Component<EditDonorProps, EditDonorState> {
  constructor(props: EditDonorProps) {
    super(props);
    this.state = {
      cancelStart: false,
      cancelConfirm: false,
      date: format(props.donor?.next_payment, dateMask) || '',
      dateSaved: false,
      sum: '',
      sumSaved: false,
    };
  }

  static getDerivedStateFromProps(nextProps: EditDonorProps, oldState: EditDonorState) {
    if (!oldState.date) {
      return {
        date: format(nextProps.donor?.next_payment, dateMask),
      };
    }
    return null;
  }

  hide = () => {
    const { hide } = this.props;
    this.setState(
      () => ({
        cancelStart: false,
        cancelConfirm: false,
        date: '',
        dateSaved: false,
      }),
      () => hide(),
    );
  };

  handleStartCancel = () => {
    this.setState(() => ({
      cancelStart: true,
    }));
  };

  handleChange = (e: SyntheticInputEvent<>) => {
    e.persist();
    this.setState(() => ({
      date: e.target.value,
    }));
  };

  handleSumChange = (e: SyntheticInputEvent<>) => {
    e.persist();
    const { value } = e.target;
    this.setState(() => ({
      sum: value,
    }));
  };

  buttonRender = () => {
    const { cancelConfirm, cancelStart } = this.state;
    const { donor } = this.props;
    const isCancelled = ['ended', 'cancelled'].includes(donor?.status);
    if ((cancelConfirm && cancelStart) || isCancelled) {
      return (
        <DeleteSuccessButton onClick={() => this.cancelSubscription()}>
          Подписка отменена
        </DeleteSuccessButton>
      );
    }
    if (cancelStart && !cancelConfirm) {
      return (
        <ConfirmCancelButton onClick={() => this.cancelSubscription()}>
          Подтвердите отмену подписки
        </ConfirmCancelButton>
      );
    }
    return (
      <CancelPaymentButton
        disabled={isCancelled}
        onClick={() => this.handleStartCancel()}
      >
        Отменить подписку
      </CancelPaymentButton>
    );
  };

  changeButtonRender = () => {
    const { sumSaved } = this.state;
    return sumSaved ? (
      <SaveButtonSuccess disabled onClick={() => this.updateSum()}>
        Сохранено
      </SaveButtonSuccess>
    ) : (
      <Popconfirm
        title="Точно изменить сумму?"
        placement="top"
        onConfirm={this.updateSum}
        okText="Да"
        cancelText="Нет"
        icon={<ExclamationCircleFilled style={{ color: 'red' }} />}
      >
        <SaveButton>Сохранить</SaveButton>
      </Popconfirm>
    );
  };

  cancelSubscription = () => {
    const {
      donor: { id, email },
      dispatch,
      searchType = 'email',
    } = this.props;
    this.setState(
      () => ({
        cancelConfirm: true,
      }),
      () => dispatch(cancelSubscription(id, email, searchType)),
    );
  };

  updateSubscription = () => {
    const { donor, dispatch, searchType = 'email' } = this.props;
    const { date } = this.state;
    const newTime = moment(date, dateMask);
    const currentTime = moment();
    if (newTime.isBefore(currentTime)) {
      message.error('Это не так работает :( Новая дата не должна быть раньше предыдущей');
      return;
    }
    dispatch(
      changeSubscriptionDate(newTime.valueOf(), donor.id, donor.email, searchType),
    );
    this.setState(() => ({
      dateSaved: true,
    }));
  };

  updateSum = () => {
    const { donor, dispatch, searchType = 'email' } = this.props;
    const { sum } = this.state;
    if (donor.status !== 'active') {
      return message.error('Менять сумму можно только у активного донора!');
    }
    if (!sum) {
      return message.warn('Введите сумму!');
    }
    if (donor?.gross_curr === 'RUB' ? parseInt(sum, 10) < 50 : parseInt(sum, 10) < 1) {
      return message.error(`Сумма должна быть больше ${donor?.gross_curr === 'RUB' ? 50 : 1} ${donor?.gross_curr === 'RUB' ? 'рублей' : donor?.gross_curr}!`);
    }
    dispatch(changePaymentSum(sum, donor.id, donor.email, searchType));
    this.setState(() => ({
      sumSaved: true,
    }));
    return undefined;
  };

  render() {
    const { donor = {}, privateKey, visible } = this.props;
    const { date, dateSaved, sum } = this.state;
    const isAllowed = Auth.allowFor(ROLES.superadmin);
    console.log('donor', donor, date);
    return [
      <Modal
        centered
        footer={null}
        visible={visible && isAllowed}
        onCancel={() => this.hide()}
        width="auto"
      >
        <StyledContent>
          <StyledTitle>Управление подпиской</StyledTitle>
          <Row>
            <Left>Начало</Left>
            <Right>
              {format(donor?.createdAt, 'DD MMMM YYYY', { locale })}
            </Right>
          </Row>
          <Row>
            <Left>Сумма</Left>
            <SumContainer>
              <Sum>{`${donor?.gross_sum} ${currency[donor?.gross_curr]}`}</Sum>
              <SumInput
                isSmallSum={donor?.gross_curr === 'RUB' ? parseInt(sum, 10) < 50 : parseInt(sum, 10) < 1}
                value={sum}
                onChange={this.handleSumChange}
              />
              {this.changeButtonRender()}
            </SumContainer>
          </Row>
          <Row>
            <Left>Окончание</Left>
            {donor?.date_end ? (
              <CancelledSubscription>
                {format(donor?.date_end, 'DD MMMM YYYY', { locale })}
              </CancelledSubscription>
            ) : (
              <ActiveSubscription>Активный</ActiveSubscription>
            )}
          </Row>
          <Row>
            <Left>Почта</Left>
            <Right>
              <Copy text={decrypt(donor?.email, privateKey)}>
                {decrypt(donor?.email, privateKey)}
              </Copy>
            </Right>
          </Row>
          <Row>
            <Left>Телефон</Left>

            <Right>
              <Copy text={decrypt(donor?.phone, privateKey)}>
                {decrypt(donor?.phone, privateKey) || '-'}
              </Copy>
            </Right>
          </Row>
          <Row>
            <Left>Способ</Left>
            <Right>{PaymentMethod[donor?.service]}</Right>
          </Row>
          <Row>
            <Left>Следующее списание</Left>
            <Right>
              <Input value={date} onChange={this.handleChange} />
              {dateSaved ? (
                <SaveButtonSuccess>Сохранено</SaveButtonSuccess>
              ) : (
                <SaveButton onClick={this.updateSubscription}>
                  Сохранить
                </SaveButton>
              )}
            </Right>
          </Row>
          {this.buttonRender()}
        </StyledContent>
      </Modal>,
    ];
  }
}

export default connect((state: FullStore): { privateKey: string } => ({
  privateKey: state.privateKey.value,
}))(EditDonor);
